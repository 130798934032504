/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

form
	div.DivForm
		h3
			margin-bottom: $SPACE_LITTLE
			font-size: $FONT_LABEL_H3
		fieldset
			legend
				font-size: $FONT_LABEL_LEGEND
				margin-top: $SPACE_LITTLE
				margin-bottom: $SPACE_LITTLE
			label
				font-size: $FONT_LABEL_GENERAL
				margin-bottom: $SPACE_NARROW
				margin-top: $SPACE_SMALL
				display: block
			label.LabelPreffix
				margin-right: $SPACE_LITTLE
			label.LabelSuffix
				margin-left: $SPACE_LITTLE
			span.SpanActiveStatus, i.IActiveStatus
				margin-right: $SPACE_MEDIUM
				vertical-align: middle
				display: inline-block
				padding-top: $SPACE_TINY
				padding-left: $SPACE_LITTLE
				padding-right: $SPACE_LITTLE
				padding-bottom: $SPACE_TINY
				font-size: $FONT_SPAN_TICKETSTATUS
				margin: 0px
				+setBorderRadius($RADIUS_TICKET_STATUS)
		span.SpanCustomerPhone
			display: flex !important
			select
				width: $WIDTH_INPUT_PHONE
				margin-right: $SPACE_LITTLE
	label.LabelAttachment
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		+setBorderRadius(50%)

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2
			font-size: 3em
		span
			font-size: $FONT_LABEL_SUBTITLE
	div.DivForm
		h2, h3
			display: block
			margin-bottom: $SPACE_LITTLE
		h2
			margin-top: $SPACE_LITTLE
			font-size: $FONT_LABEL_H2
		h3
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H3
		h3:first-of-type
			margin-top: 0px
		h4
			margin-top: $SPACE_MEDIUM
			font-size: $FONT_LABEL_H4
		h4:first-of-type
			margin-top: 0px
		label
			font-size: $FONT_LABEL_GENERAL
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_SMALL
			display: block
			i
				font-size: 15px
				font-weight: 550
		label.mdc-floating-label
			line-height: auto
			margin-top: -$SPACE_LITTLE
			margin-bottom: 0px
		div.DivHeaderHeadline
			div.DivSubHeaderHeadline
				label
					margin-top: 0
		span, p, textarea#spanIncidentNote
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			display: block
		span, p
			word-break: break-all
		span.SpanRupiah
			font-size: $FONT_LABEL_PROPERTY
			margin-bottom: $SPACE_SMALL
			margin-top: $SPACE_NARROW
			word-break: inherit
		.mat-mdc-form-field, .legend-wrap
			span
				font-size: inherit
				margin-bottom: 0px
				margin-top: 0px
				display: inline-block
				.legend-label-text
					word-break: break-word
					white-space: normal
		.legend-wrap
			.horizontal-legend
				li
					display: block
		dl
			dt, dd
				font-size: $FONT_LABEL_HEADERINFO
		div.DivHeaderHeadline
			div.DivHeaderStatus
				span
					font-size: $FONT_LABEL_SUBTITLE
		fieldset
			div.DivHeaderInfo
				dl
					dt, dd
						font-size: $FONT_DATALIST_HEADERINFO

div.DivProfileInfo
	div.DivProfileInfoDetails
		a.Underline
			text-decoration: underline
	div.DivProfilePhoto
		span.SpanChatProfileNameInitial
			margin: 0px
			font-family: $URL_FAMILYSECONDARY_PRIMARY
			color: white
			font-size: $FONT_SPAN_PROFILENAMEINITIAL
			display: block
			text-align: center

div#divBodyAbout, div#divBodyEditProfile, div#divBodyNotification
	div.DivContentMain
		div.DivContentAbout, div.DivContentEditProfile
			p
				line-height: 35px
				text-align: justify
	div.DivContentDescription
		display: flex
		margin-top: 20px
		margin-bottom: 20px
		h1
			display: block
			font-size: 3.5em
			margin-top: 20px
		p
			font-size: 1.1em
			font-weight: 550
	div#divContentWhatBRINS
		h1
			font-size: 3em
		div.DivContentAbout
			p
				line-height: 38px

// GENERAL - END


// LANDING - START

div#divBodyDashboard1
	div.DivContentDashboard
		div.DivContentSection
			div.DivContentTitle
				h3
					font-size: 4em
					font-weight: bold
					color: white
					line-height: 1.4
			div.DivContentDescription
				p
					font-size: 1.25em
					color: white

// LANDING - END


// NAVIGATION - START

nav
	figure
		text-align: center
		span#spanProfileNameInitial
			font-size: $FONT_LABEL_PROFILENAMEINITIAL
			display: block
		figcaption
			span#spanProfileName, span#spanProfilePosition
				display: block
			span#spanProfileName
				font-size: $FONT_LABEL_PROFILENAME
			span#spanProfilePosition
				font-size: $FONT_LABEL_PROFILEPOSITION
	ul
		list-style-type: none
		li
			margin-top: $SPACE_LARGE
			margin-bottom: $SPACE_LARGE
	div
		div
			a
				text-decoration: none
	ul.ListNavigation
		li.ListItemNavigation
			// font-size: $FONT_LIST_NAVIGATIONITEM
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				li.ListItemSubNavigation
					font-size: $FONT_LIST_NAVIGATIONSUBITEM

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	header#headerSignIn
		h1, span
			display: block
		h1
			font-size: $FONT_LABEL_H1
			letter-spacing: $SPACE_LITTLE
			margin-bottom: -$SPACE_LITTLE
		span
			font-size: $FONT_LABEL_SPAN
	form
		div.DivNotRegistered
			text-align: center
			font-weight: 500

// SIGN IN - END


// DASHBOARD - START

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		margin-top: $SPACE_MEDIUM
		margin-bottom: $SPACE_MEDIUM
		text-align: center
		span.SpanHighlight
			padding-top: $SPACE_LITTLE
			padding-bottom: $SPACE_LITTLE
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			margin: $SPACE_LITTLE
			min-width: $WIDTH_LABEL_HIGHLIGHT
			display: block
			+setBorderRadius($RADIUS_LABEL_HIGHLIGHT)

form div.DivForm fieldset, div.DivForm
	ul
		list-style: none
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormListHorizontalContainer
		div.DivListChart
			span
				font-size: $FONT_DASHBOARD_SPAN
			ul
				padding: $SPACE_LITTLE
				+setBorderRadius($RADIUS_DASHBOARD_LIST)
				li
					list-style: none
					margin-top: $SPACE_NARROW
					margin-bottom: $SPACE_LITTLE
					span
						font-size: $FONT_DASHBOARD_SPANLIST
						display: inline
					span:first-of-type
						+setBorderRadius($RADIUS_DASHBOARD_LIST)
						padding: $SPACE_TINY $SPACE_LITTLE
						margin: $SPACE_TINY

// DASHBOARD - END


// TABLE - START

table
	thead, tbody
		tr
			th
				font-weight: 500
				font-size: $FONT_SPAN_TABLE_HEAD
			td
				font-weight: 500
				font-size: $FONT_SPAN_TABLE_HEAD
				text-align: left
				span
					font-size: $FONT_SPAN_TABLE
			td.TableCenter
				text-align: center
			td:nth-child(3)
				width: 350px

div.DivTableContainer
	div.DivTableControl
		span.SpanTablePage
			margin-left: $SPACE_MEDIUM
			margin-right: $SPACE_MEDIUM
			font-size: $FONT_SPAN_TABLE
		label
			margin-right: $SPACE_MEDIUM
			white-space: nowrap
			font-size: $FONT_SPAN_TABLE
			strong
				padding: $SPACE_TINY $SPACE_LITTLE
	ul.ListTableThumbnails
		figure
			figcaption
				font-size: $FONT_LABEL_GENERAL
	ul.ListTableTicket, ul.ListTableConversation
		div.DivTicketItem, div.DivConversationGeneral
			span.SpanTicketTitle
				font-size: $FONT_LIST_TABLETITLE
			div.DivTicketSubItem, div.DivConversationItem
				div.DivTicketHeader, div.DivConversationHeader
					span
						display: block
					span.SpanTicketNumber, span.SpanConversationDate
						font-size: $FONT_DATA_TICKETLABEL
					span.SpanTicketTitle, span.SpanConversationTitle
						font-size: $FONT_LIST_TABLETITLE
						display: block
					span.SpanConversationDate
						text-align: right
					span.SpanTicketNumber
						display: inline-flex
						p
							margin-left: 5px
							border-left: solid 3px white
							padding-left: 5px
				div.DivTicketTail, div.DivConversationStatus
					span
						margin-right: $SPACE_MEDIUM
						vertical-align: middle
						display: inline-block
						padding-top: $SPACE_TINY
						padding-left: $SPACE_LITTLE
						padding-right: $SPACE_LITTLE
						padding-bottom: $SPACE_TINY
						font-size: $FONT_SPAN_TICKETSTATUS
						+setBorderRadius($RADIUS_TICKET_STATUS)
					span:first-of-type
						margin-left: 0px
						margin-bottom: $SPACE_LITTLE
					span:last-of-type
						margin-right: 0px
		li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						span.SpanConversationDate
							text-align: left
		dl
			dt, dd
				margin-left: 0px
				margin-right: 0px
				margin-bottom: $SPACE_THIN
				margin-top: $SPACE_THIN
				font-size: $FONT_DATA_TICKETLABEL
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
	table.TableListDetails
		font-size: 0.9em
		font-weight: 600
	table.TableListDetailsAttachment
		input[type=file]
			display: none
		label.LabelAttachment
			display: block
			width: $SIZE_ICON_LDPI
			height: $SIZE_ICON_LDPI
			+setBorderRadius(50%)
		div.DivContentButtonDetail
			text-align: center
			a.ButtonForward
				span
					line-height: 18px
					font-weight: 600
					vertical-align: middle
					font-size: 1.2em

	table.TableBox
		tbody
			td
				text-align: center

// TABLE - END


// DYNAMIC CONTAINER - START

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	div.DivForm
		div.DivListControl
			h3
				margin: 0px
			div.DivListControlButton
				label
					margin-left: $SPACE_LITTLE
					margin-right: $SPACE_LITTLE
					font-size: $FONT_SPAN_TABLE
				label, input[type=button]
					display: inline-block

div.DivDynamicContainer
	form.FormInsertConversation
		div.DivConversationGeneral
			div.DivConversationHeader
				h3
					margin: 0
				div.DivConversationInfo
					div.DivConversationInfoDetails
						span
							margin: 0px
							text-align: right
			div.DivConversationContent
				div.DivAttachmentContainer
					label
						margin: 0px
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					margin: 0px
					font-family: $URL_FAMILYSECONDARY_PRIMARY
					color: white
					font-size: $FONT_SPAN_PROFILENAMEINITIAL
					display: block
					width: $SPACE_BIG
					height: $SPACE_ENORMOUS
					padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
					text-align: center

div#divBodyClaimProposal
	div#divMainClaimProposalDetailApproval
		div.DivDynamicContainer
			margin-right: $SPACE_SMALL
			form
				margin-right: 0px
			div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
				label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
					a.NotVerified, a.Verified
						font-size: $FONT_LABEL_SPAN

// DYNAMIC CONTAINER - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	span
		font-size: 1em
		font-weight: bold
	input#buttonNotificationClose
		font-size: $FONT_SIZE_CLOSE
	p#paragraphNotificationInstallIOS
		display: none
	p.ParagraphNotificationInstallOthers
		font-size: 0.7em
div#divNotificationInstallList
	span
		display: block
		margin-bottom: $SPACE_TINY

// NOTIFICATION - END


// LOADING - START

div#divCurtainLoading
	div#divLoadingMessage
		span#spanLoadingPercentage
			margin-bottom: $SPACE_HUGE
			font-size: $FONT_SPAN_LOADINGPERCENTAGE
		span
			font-size: $FONT_LABEL_GENERAL

// LOADING - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	span
		font-size: $FONT_LABEL_GENERAL
		font-weight: bold
	input#buttonNotificationClose
		font-size: $FONT_SIZE_CLOSE
	p#paragraphNotificationInstallIOS
		display: none
div#divNotificationInstallList
	span
		display: block
		margin-bottom: $SPACE_TINY

// NOTIFICATION - END


// APPROVAL - START

div.DivForm
	div.DivCard, div.DivCardFinish
		span#spanTitle
			font-size: 12pt
		span#spanName
			margin-top: -7.5px
			font-size: 16pt

// APPROVAL - END


// RETURN AMOUNT START

div.DivForm
	div.DivFormReturnAmount
		fieldset
			h3:first-of-type
				margin-top: -15px
			h3
				margin-top: -15px
				font-size: 12pt
				align-items: center


// RETURN AMOUNT END


// INFO WINDOWS GOOGLE MAPS PLATFORM START

div.DivDynamicContainer
	map-info-window
		a
			font-weight: bold
			margin-bottom: $SPACE_LITTLE
		p
			margin-top: $SPACE_LITTLE

div.DivFormBranch
	span
		font-weight: bold
		text-transform: capitalize
		
// INFO WINDOWS GOOGLE MAPS PLATFORM END

// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		div.DivFormBranch
			p
				position: absolute
				font-size: $SPACE_MEDIUM
				right: 0
				margin-top: 18px
				margin-right: 155px

// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //


// MONITORING RENEWAL - START

div.DivListContainer
	div.DivTableListContainer, div.DivTableListContainerLeft
		div.DivTableListControl, div.DivTableListControlLeft
			span.SpanTablePage
				margin-left: $SPACE_MEDIUM
				margin-right: $SPACE_MEDIUM
				font-size: $FONT_SPAN_TABLE
			label
				margin-right: $SPACE_MEDIUM
				white-space: nowrap
				font-size: $FONT_LABEL_GENERAL

div.DivCardPolicy
	span, h2
		margin-left: $SPACE_MEDIUM
		margin-top: $SPACE_TINY
	span
		display: block
		font-size: $FONT_LABEL_SUBTITLE
	h2
		font-size: $FONT_LABEL_H2

div.DivCardInformationPolicy
	span
		margin-left: $SPACE_LITTLE
		display: block
		font-size: $FONT_LABEL_SPAN
	h2
		margin-left: $SPACE_LITTLE
		font-size: $FONT_LABEL_SPAN

div.DivCardInformationPolicy
		span.spanStatusPolicyClosed
			padding: $SPACE_TINY
			+setBorderRadius($RADIUS_STATUS_POLICY)
		span.spanStatusPolicyInforce
			padding: $SPACE_TINY
			+setBorderRadius($RADIUS_STATUS_POLICY)
		span.spanStatusPolicyTemporary
			padding: $SPACE_TINY
			+setBorderRadius($RADIUS_STATUS_POLICY)
		span.spanStatusPolicyWaiting
			padding: $SPACE_TINY
			+setBorderRadius($RADIUS_STATUS_POLICY)
		span.spanStatusExecutive
			padding: $SPACE_TINY
			+setBorderRadius($RADIUS_STATUS_POLICY)

// MONITORING RENEWAl - END


// BRINS-WEBSITE START

div#divMainDashboard
	h1
		margin-top: 20px
		font-size: $FONT_LABEL_H1
	h2
		font-size: $FONT_LABEL_H2
	h3
		font-size: $FONT_LABEL_H3
	h4
		font-size: $FONT_LABEL_H4
	p
		font-size: $FONT_LINK_GENERAL
		text-align: justify
		margin: 10px auto
	p.ParagraphWhiteSpace
		white-space: pre-wrap
	select
		font-size: $FONT_LABEL_SUBTITLE
	span.SpanContentPage
		margin: 0 2px

	div#divBodyFooterContainer
		div.DivFooterInfoPage
			a
				text-decoration: none
			label
				font-size: $FONT_LABEL_FOOTER
			a.AFooterSVGContainer
				img
					width: $WIDTH_LAYOUT_LOGOHEADER
			p
				font-size: $FONT_LABEL_SPAN
				font-weight: 550
				line-height: $FONT_PARAGRAPH_LINE_HEIGHT_MEDIUM
				margin: 0px
			div.DivFooterCopyright
				p.PLabelCopyright
					font-weight: 600
				p.PTextCopyright
					font-size: $FONT_LIST_NAVIGATIONSUBITEM
					line-height: $SIZE_LINK_BUTTONATTACHMENT
				hr
					height: 2px
	div#divBodyPartnerAndClient, div#divBodyTabAbout, div#divBodyStructureOrganization
		div.DivContentMain
			h1
				font-size: $FONT_LABEL_HEADERCONTENT
				margin-bottom: $SPACE_HUGE
				font-weight: 600
		div.DivContentDescription
			h1
				font-size: $FONT_LABEL_HEADERCONTENT
				margin-bottom: $SPACE_HUGE
				font-weight: 600
				text-align: center
			h3
				font-size: $FONT_LABEL_HEADERCONTENT
				margin-bottom: $SPACE_SMALL
				font-weight: 700
			h4
				font-size: $FONT_LABEL_H4
				margin-bottom: $SPACE_MEDIUM
				font-weight: 600
			p
				font-size: $FONT_DATALIST_HEADERINFO
				text-align: justify
				margin-bottom: $SPACE_SMALL
				line-height: 38px
	div#divBodyStructureOrganization
		div.DivContentMain, div.DivContentMainDivision
			h1
				font-size: $FONT_LABEL_TITLE_CLAIM

div#divBodyPartnerAndClient
	h1
		text-align: left
		margin: $SPACE_HUGE $SPACE_LITTLE
		font-size: $FONT_LABEL_TITLE_CLAIM
	div.DivContentBoxDescription
		text-align: center
div.DivConversationMelisa
	div.DivBannerMelisa
		div.DivBanner
			text-align: right
			p
				font-size: $FONT_LABEL_BANNER_MELISA
	div.DivConversation
		div.DivConversationHeader
			label
				margin: auto 10px
		div.DivConversationDetail
			div.DivConversationMessage
				p
					margin: 5px 10px


div.DivContentPartnershipWorkshop
	text-align: center
	div.DivContentDescription
		margin: auto

div#divMainDashboard
	div.DivBodySiteContainer
		div.DivContentAwardsDetail
			label
				padding: 5px 15px
				margin: 20px 15px 0px 15px
				width: max-content
				border-radius: 10px
				+setBorderRadius(10px)
			h5
				margin: 15px
			a
				span
					font-size: $FONT_SPAN_TABLE

div.DivContentButtonDetail
	a.ButtonForward span
			position: relative
			background-color: transparent
	a.ButtonForwardTable span
			position: relative
			background-color: transparent
			font-size: 1em

// START - HOME

div#divBodyHomeMenu
	nav
		div.DivNavigationList
			a
				font-size: 12pt
			li.ListNavigationMenu
				div.DivNavigationDetail
					a
						margin: 2px 2px
			ul
				ul
					li
						a
							font-size: 11pt
						a.ANavigationMenuList
							height: 25px
							width: auto

// END - HOME


// START - DASHBOARD


div#divBodyDashboard1, div#divBodyDashboardAward
	p
		text-align: center
div#divBodyDashboardBussinesPillars
	h1
		font-size: $FONT_LABEL_TITILECONTENT
	p
		text-align: center
div#divBodyDashboardBRINSUpdate
	h1
		font-size: $FONT_LABEL_TITILECONTENT
	p
		font-size: $FONT_LABEL_HEADERINFO
		span
			font-size: $FONT_DATA_TICKETLABEL
	div.DivContentCorporateTitle
		p
			margin: 20px auto
	div.DivContentDescription
		h1
			text-align: center
			font-size: $FONT_LABEL_HEADERCONTENT
div#divBodyDashboardPartners
	div.DivContent
		h1
			text-align: center
			font-size: $FONT_LABEL_HEADERCONTENT

div#divBodyDashboardAboutBRINS
	h1
		font-size: $FONT_LABEL_H3
		font-weight: 550
		margin-right: 0px
		margin-left: 0px
		letter-spacing: 5px
		width: 28%
	hr
		width: 100%
	p
		font-size: 2.75em
		text-align: left
		margin-left: 100px
		margin-bottom: 50px

div#divBodyDashboardAward
	div.DivContentAwardsTitle
		h1
			font-size: $FONT_LABEL_TITILECONTENT
			margin-top: 0px

div#divBodyDashboardPartners
	h2
		font-size: 35pt
		margin-top: 0px
	div.DivContentPartners
		div.DivContent2Column
			div.DivContent
				p
					margin: 20px auto

// END - DASHBOARD


// START - ABOUT

div#divBodyMissionAndVission
	div.DivContentDescription
		p
			line-height: $SPACE_HUGE
	div.DivContentMain
		label
			font-size: $FONT_LABEL_HEADERINFO

// END - ABOUT

// START - SOCIAL VALUE

div#divMainDashboard
	div#divBodySocialValueCEO
		div.DivContent2Column
			div.DivContent
				h2, p, label
					margin: auto
				h2
					font-size: $FONT_LABEL_TITILECONTENT
				label
					font-size: $FONT_LABEL_HEADERINFO
					margin-bottom: $FONT_PARAGRAPH_LINE_HEIGHT
				p
					line-height: $FONT_PARAGRAPH_LINE_HEIGHT
	div#divBodySocialValueCSR
		div.DivContentDescription
			h1
				font-size: $FONT_LABEL_TITILECONTENT
			div.DivContentMain
				div.DivContentBoxDetail
					p
						margin: 0px
						text-align: left
	div#divBodySocialValueInformation
		h1
			font-size: $FONT_LABEL_TITILECONTENT
			margin: 0px
		div.DivContentBoxDetail
			div.DivContentBoxDescription
				text-align: center
				h3
					margin-top: 10px
					font-size: $FONT_LABEL_HEADERINFO
		div#divBodyTabAbout
			div.DivContentTabAbout
				button
					font-size: $FONT_LABEL_SUBTITLE
			div.DivContentMain
				p
					line-height: $FONT_PARAGRAPH_LINE_HEIGHT
					margin: 0px
				p.ParagraphBold
					margin-top: 10px
		div.DivContentDescription
			div.DivContentMain
				h3
					text-align: left

div#WhistleblowingSystemForm
	div.DivForm
		select, input, textarea, label
			font-size: $FONT_LABEL_HEADERINFO

// END - SOCIAL VALUE

div#divBodyContentOrdered
	p
		line-height: 38px
	h1
		font-size: $FONT_LABEL_TITILECONTENT
		margin-bottom: $SPACE_LARGE
	div.DivBodyContentOrderedList
		h4
			font-size: $FONT_LABEL_TITLE_ECONOMICVALUE
		div.DivContentOrderedListDescription
			h4.headingFirstLetterBig:first-letter
				font-size: $FONT_LABEL_H2
			h2.headingFirstLetterBig:first-letter
				font-size: $FONT_LABEL_H2
			p
				margin: 0px
			h1
				margin-top: 25px
				margin-bottom: 15px

div#divBodyContentMilestone, div#divBodyFormOutlook
	h1
		font-size: $FONT_LABEL_TITILECONTENT


div#divContent2ColumnBrillianWays
	div.DivContent
		div.DivContentOrderedListDescription
			h1, p
				text-align: right

div#divBodyDashboardAward
	h1
		color: #064F89
		margin-top: 10px
	h2
		letter-spacing: 2px
		font-weight: 900
		font-size: 36pt
	div.DivContentPartners
		p
			font-size: $FONT_LABEL_HEADERINFO
			line-height: 35px
	div.DivContentAwardsDescription
		text-align: center
		p
			font-size: 15pt
			color: #a9a9a9
	div.DivContentAwardsPage
		text-align: center
	div.DivContentAwardsContent
		div.DivContentCalenderDetail
			div.DivContentCalenderDate
				p
					font-weight: 700
					margin: 15px auto
				h3
					margin: 0px auto
					font-size: 2.5em
					font-family: 'Poppins-Bold'
			div.DivContentCalenderDescription
				span, p
					margin-right: 20px
					font-size: $FONT_DATALIST_HEADERINFO
				p
					font-size: 1em
					font-family: 'Poppins-SemiBold'
					text-align: left
					margin: auto 10px

div#divBodyTabAbout
	h1
		font-size: $FONT_LABEL_TITILECONTENT
	div.DivContentTabAbout
		button
			font-size: 1.1em
			font-weight: 600
		button:hover
			cursor: pointer
	div.DivContentDownload
		div.DivContentDownloadLarge
			text-align: center
		div.DivContentDownloadMedium
			text-align: left
		div.DivContentDownloadLarge, div.DivContentDownloadMedium
			label
				margin: $SPACE_TINY $SPACE_LITTLE
				font-size: $FONT_LABEL_HEADERINFO
	div.DivContentMain
		h3
			margin-bottom: $SPACE_MEDIUM
div.DivContentSearch
	input
		font-size: $FONT_LABEL_HEADERINFO

div#divMainDashboard
	div.DivContentButtonDetail
		text-align: center
		.ButtonForward
			span
				line-height: 18px
				font-weight: 600
				vertical-align: middle
				font-size: 1.2em
		.ButtonPlay
			span
				position: relative
				background-color: transparent
				margin-left: 40px
				line-height: 18px
				font-weight: 600
				vertical-align: middle
				font-size: 1.2em

div.DivContentPoint
	text-align: center

div#divBodyInformation
	div.DivContentMain
		div.DivContentTextMain
			p
				text-align: left
				line-height: 40px
				font-size: $FONT_LABEL_H4
	div.DivContentDescription
		div.DivContentBoxDetail
			div.DivContentBoxDescription
				p
					text-align: justify

div#divMainDashboard
	div.DivContentMilestoneDetail
		div.DivContentPoint
			span
				font-size: $FONT_LABEL_HEADERCONTENT


// START - JOINUS

div#divContentJoinUsCareers, div#divContentJoinUsPartnership
	div.DivContentDescription
		text-align: center
		h2
			font-size: $FONT_LABEL_TITILECONTENT
		p
			font-size: $FONT_LABEL_HEADERINFO
			text-align: center

div#divContentBRINSAPI
	h2
		font-size: $FONT_LABEL_TITILECONTENT
	div.DivContentButtonDetail
		text-align: left

// END - JOINUS


// START - CUSTOMER CARE

div#divContentTab
	button
		font-size: 1.1em
		font-weight: 600
	button:hover
		cursor: pointer
div#divContentPartnerWorkshop
	padding: 50px 100px
	h2
		font-size: $FONT_LABEL_TITLE_CLAIM
	label
		font-size: $FONT_LABEL_H3
		font-weight: bold
	div#divContentCheckPolicy
		label
			font-size: $FONT_LABEL_H3
			font-weight: bold

div#divContentPartnerWorkshop
	p
		text-align: left

div#divContentBoxDetailClaim
	h3
		font-size: $FONT_LABEL_HEADERINFO

div#divContentTicketAndCallCenter
	h2, p
		text-align: center
	h2
		font-size: $FONT_LABEL_TITLE_CLAIM
	label
		font-size: $FONT_LABEL_H3
	div.DivForm
		label
			font-size: $FONT_INPUT_GENERAL
			font-weight: bold
			color: black
	div.DivContentDescription
		div.DivContentDetail
			h3
				text-align: center
			p
				line-height: 15px

div#divMainCustomerCareSubmissionClaim
	h1
		font-size: $FONT_LABEL_TITILECONTENT
	h1, p
		text-align: left
		margin: 0px

div#divMainBRINSUpdateTestimony
	div.DivContentTestimonyDetail
		p
			font-size: $FONT_PARAGRAPH_CONTENT
			word-break: break-word
			text-align: left
		p#paragraphName
			font-weight: bold

button.ButtonSubmit
	font-size: $FONT_INPUT_GENERAL

div#divContentTicketAndCallCenter
	div.DivContent
		div.DivContentDescription
			p:hover
				cursor: pointer
div#divMainDashboard
	div.DivBRINSUpdateArticleDetail
		p, li
			font-size: $FONT_INPUT_TEXTAREA_HEADERHEADLINE

// END - CUSTOMER CARE


// START - SEARCH

div#divBodySearch
	div.DivContentSearchContainer
		div.DivContentResult
			h2
				margin: 20px 0px
				text-align: left
			div.DivContentResultDescription
				h3
					margin: 0px
		div.DivContentNotFound
			h2
				font-size: 3em

// END - SEARCH


// START - BRINS Update

div#divMainBRINSUpdate
	div.divBodyHeadlines
		h1
			text-align: left
			margin: $SPACE_HUGE $SPACE_LITTLE
			font-size: $FONT_LABEL_TITLE_CLAIM
	div#divFinancialLiteracy
		h2
			font-size: $FONT_LABEL_TITILECONTENT
			margin: 0px

div#divMainBRINSUpdateArticle
	p
		line-height: 38px
		margin-bottom: 25px
		white-space: pre-line
	label
		font-size: 1.25em
		font-weight: bold
	label.LabelDateArticleEditorial
		background-color: #B8C9DB
		border-radius: 12px
		padding: 15px
		color: #1C487C
	h2
		margin-bottom: 20px

div#divBodyHeadlines
	h2
		margin: auto 0px
		font-size: $FONT_LABEL_TITILECONTENT
	p
		font-size: 1em
		text-align: left
		line-height: 30px
	p.ParagraphBold
		line-height: 24px

div.DivContentNewsHorizontal, div.DivContentNewsVertical
	div.DivContentNewsDetail
		label
			display: flex
			padding: 5px 15px
			width: max-content
			border-radius: 10px
			+setBorderRadius(10px)
		a
			float: right
			margin-right: 10px
div.DivContentNewsVertical
	a
		margin: auto 0px
		float: right
	h3
		margin-top: 10px

// END - BRINS Update


// START - PRIVACY

div#divMainDashboard
	div.DivHeaderPolicy
		h1
			font-size:  $FONT_TITLE_PRIVACYPOLICY
	div.DivMainPolicy
		h1
			font-size: $FONT_LABEL_TITILECONTENT
		p
			line-height: $FONT_PARAGRAPH_LINE_HEIGHT
		label
			font-size: $FONT_LABEL_HEADERINFO

// END - PRIVACY


// START - TERMS AND CONDITIONS

// END - TERMS AND CONDITIONS


//START - FAQ

div.DivDetailFAQ
	label
		font-size: $FONT_INPUT_TEXTAREA_HEADERHEADLINE !important
	p
		font-size: $FONT_LABEL_SUBTITLE !important

//END - FAQ

// BRINS-WEBSITE END

div.DivForm, form div.DivForm fieldset
	div.DivRadioButtonContainer
		label
			margin: 5px


// PNM DOWNLOAD - START

div#divHeaderDownload
	header#headerDownload
		h1, span
			display: block
		h1
			font-size: $FONT_LABEL_TITILECONTENT
			letter-spacing: $SPACE_LITTLE
			margin-bottom: -$SPACE_LITTLE
		span
			font-size: $FONT_LABEL_SPAN
	form
		div.DivNotRegistered
			text-align: center
			font-weight: 500

// PNM DOWNLOAD - END