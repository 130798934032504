/* @charset "utf-8" */
/* CSS Document */

// IMPORT - START

@import form-initialization-general
@import form-setup

// IMPORT - END


// GENERAL - START

form
	width: 100%
	div.DivForm
		padding: $SPACE_MEDIUM
		fieldset
			.divMatFormFieldPeriod
				width: 100%
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputHorizontalContainer
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				justify-content: space-between
		fieldset:last-of-type
			margin-bottom: 0px
	div.DivFormBox
		+setBorderRadius(30px)
		+boxShadow(0, 0, 8px, 0, rgba(0, 0, 0, 0.2))
		fieldset
			margin: 30px
			width: auto
	ul.UlListAsignee
		display: flex
		flex-flow: row wrap
		align-content: flex-start
		justify-content: space-between
		li
			box-sizing: border-box
			margin: 0
			width: 250px
			height: auto

div.DivForm
	div.DivPercentageProgress
		position: relative
		height: 40px
		width: 100%
		+setBoxSizing(border-box)
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		div#divSubPercentageProgress
			position: absolute
			height: 40px
			width: 30%
			overflow: hidden
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		span
			position: relative
			color: white
			margin-left: 44%
			padding-top: 10px
			+setBoxSizing(border-box)
			+setBorderRadius($RADIUS_LAYOUT_FORM)
	.DivRatingStar
		direction: rtl
		unicode-bidi: bidi-override
		label
			color: #F5F6F6
			font-size: 3em !important
		label:hover, label:hover ~ label
			color: #E5C44C
		input[type="radio"]:checked ~ label
			color: #E5C44C
		input[type="radio"]
			display: none

div.DivForm
	div.DivCard, div.DivCardFinish
		width: 100%
		height: 100%
		margin-bottom: 10px
		margin-top: 5px
		div.DivContainerIcon
			position: relative
			display: flex
			flex-direction: row
			justify-content: flex-end
			padding: 0px
			div.DivBorderImage
				display: flex
				justify-content: center
				align-items: center
				width: 32px
				height: 32px
				margin-right: 10px
				background-color: white
				+setBorderRadius(50%)
				img#imageQoute
					width: 26px
					height: 24px
					object-fit: cover
				img#imageApprove
					width: 26px
					height: 24px
					object-fit: cover

div.DivCard, div.DivCardFinish
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivContainerIcon
	padding-left: 10px
	padding-right: 10px
	padding-bottom: 10px
	padding-top: 10px
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

div.DivVerifierGeneral, div.DivCenterGeneral
	position: relative
	div#divMainDashboard
		div.DivContainer
			margin-top: $SPACE_HUGE
	div.DivForm
		h3
			font-size: 2em !important
		div.DivInfoContainer
			display: flex
			div
				width: 100%

div.DivMainGeneral, div.DivMainAsideSearch
	margin-top: $SPACE_MEDIUM
	width: 100%
	height: 100%

div.DivMainAsideSearch
	display: flex
	flex-wrap: nowrap
	justify-content: space-between
	align-content: stretch
	flex-basis: $WIDTH_LAYOUT_SIGNIN

div.DivContainerHorizontal, div.DivContainerVertical, div.DivContainerListHorizontal
	width: 100%
	height: 100%
	margin-top: $SPACE_MEDIUM
	display: flex
	flex-wrap: nowrap
div.DivContainerListHorizontal
	flex-direction: column
	div.DivListContainer
		display: flex
div.DivContainerHorizontal
	flex-direction: row
	div.DivContainer
		margin-left: $SPACE_SMALL
		margin-right: $SPACE_SMALL
		display: flex
		flex-direction: column
	div.DivContainer:first-of-type
		margin-left: 0px
	div.DivContainer:last-of-type
		margin-right: 0px
div.DivContainerVertical
	flex-direction: column

div.DivForm, div.DivFormBranch
	margin-top: $SPACE_SMALL
	margin-bottom: $SPACE_SMALL
	padding-left: $SPACE_MEDIUM
	padding-right: $SPACE_MEDIUM
	padding-bottom: $SPACE_MEDIUM
	padding-top: $SPACE_MEDIUM
	+setBoxSizing(border-box)
	+setBorderRadius($RADIUS_LAYOUT_FORM)

form div.DivForm fieldset, div.DivForm, div.DivCard, div.DivCardFinish
	div.DivFormListHorizontalContainer
		display: flex
		justify-content: space-around
		align-content: stretch
		align-items: flex-end
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer
		display: flex
		justify-content: space-between
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap // nowrap
		div.DivListChart
			margin-top: $SPACE_MEDIUM
			align-self: flex-start
			ul
				height: $HEIGHT_CHART_LIST
				overflow-y: scroll
	div.DivFormHorizontalAttachmentContainer
		align-items: center
		gap: 10px
		background-color: #F3F3F4
		height: 60px
		border-radius: 12px
	div.DivFormHorizontalContainer, div.DivFormListHorizontalContainer
		flex-direction: row
		div.DivForm2Column
			margin-left: $SPACE_SMALL
			margin-right: $SPACE_SMALL
			span.SpanCheckoutAmount
				color: black
				font-size: 2em !important
			div.DivDateTimePicker
				label
					font-family: "Poppins-SemiBold"
				mat-form-field
					// margin-top: 0px !important
					box-sizing: border-box
					border: none
					.mat-mdc-text-field-wrapper
						padding: 0px
						.mat-mdc-form-field-flex
							height: 50px
							padding-top: 0px
							background-color: #EFEFEF
							border: none
							.mat-mdc-form-field-label-wrapper
								label
									font-size: 1em !important
									font-family: "Poppins-Regular"
									color: #767676
							.mat-mdc-form-field-infix
								border: none
								padding-bottom: 0px
								padding-top: 25px
								padding-left: 0px
								top: 5px
								label.mdc-floating-label
									top: unset !important
									font-size: 1em !important
									color: #767676
								input
									// +setBorderRadius(0px)
									border: none
									color: #000
									background-color: transparent
							.mat-mdc-form-field-suffix
								margin: 0
					.mat-mdc-form-field-subscript-wrapper
						display: none
		div.DivForm2Column
			margin-left: $SPACE_LITTLE
			margin-right: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-left: 0px
		div.DivForm2Column:last-of-type
			margin-right: 0px
		div.DivForm2ColumnNoMargin
			margin-left: 0px
			margin-right: 0px
		div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
			div.DivImageCapture
				width: 50%
	div.DivFormVerticalContainer
		flex-direction: column
		div.DivForm2Column
			margin-top: $SPACE_LITTLE
			margin-bottom: $SPACE_LITTLE
		div.DivForm2Column:first-of-type
			margin-top: 0px
		div.DivForm2Column:last-of-type
			margin-bottom: 0px
	div.DivForm2Column
		flex-basis: 1
		flex-grow: 1
		flex-shrink: 1
		width: 45%
		span.SpanActiveStatus, IActiveStatus
			width: $WIDTH_LABEL_POLICYGENERAL_STATUS
			text-align: center

div.DivForm:first-of-type
	margin-top: 0px

div.DivForm:last-of-type
	margin-bottom: 0px

div.DivRoundButtonContainer
	display: flex
	justify-content: flex-end
	input[type=button]
		margin: 0px !important
	label
		margin-top: $SPACE_LITTLE !important

div.DivCenterButtonContainer
	display: flex
	flex-direction: column
	align-items: center

div#divBodyClaimProposal
	div#divMainClaimProposalDetailApproval
		div.DivDynamicContainer
			margin-right: $SPACE_SMALL
			form
				margin-right: 0px
			div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
				label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
					a
						border-radius: 5px
						padding: 2px 5px
	div.DivMainAsideSearch
		div.DivDynamicContainer, div.DivTableContainer
			margin-right: $SPACE_SMALL

div#divMainClaimProposalDetailApproval
	div.DivDynamicContainer
		table.TableOwnRetention
			width: 100%
			tr
				td.TdOwnRetentionnFirst
					width: 40%
				td.TdOwnRetentionnSecond
					width: 15%
					text-align: right
				td.TdOwnRetentionnTotal
					width: 45%
					text-align: right

.CKEditor
	div.cke_chrome
		border-radius: 10px
		border: 0px
		box-shadow: 0 0 3px rgb(0 0 0 / 15%)
	span.cke_voice_label
		display: none !important
		margin: 0px !important
	span.cke_top, span.cke_reset_all
		margin: 0px !important
		padding: 0px !important
		border-radius: 10px 10px 0px 0px
		box-shadow: 0 0 3px rgb(0 0 0 / 15%)
		span.cke_toolbar, span.cke_toolbar_last
			margin: 0px !important
			padding: 10px
			span.cke_toolbar_start
				display: none !important
			span.cke_toolgroup
				margin: 0px !important
				span
					margin: 0px !important
				span.cke_button_label
					display: none
				span#cke_14_label
					display: none !important
					margin: 0px !important
				span#cke_15_label
					display: none !important
					margin: 0px !important
		span.cke_toolbox
			margin: 0px !important
	div.cke_contents
		border-radius: 0px 0px 10px 10px
		box-shadow: 0 0 3px rgb(0 0 0 / 15%)
		div.cke_wysiwyg_div
			ul
				list-style: unset
			p
				font-family: 'Poppins-Regular'
				color: black
	.cke_inner
		display: grid

.MarginBottom30
	margin-bottom: 30px

.MarginBottom15
	margin-bottom: 15px

.Margin0
	margin: 0px !important

.PaddingTop15
	padding-top: 15px

.DivColumnContainer
	display: flex
	flex-direction: column

.DivHeaderRowContainer
	display: flex
	justify-content: space-between
	align-items: center
	span
		color: #F6945B
		cursor: pointer

.DivHorizontalReverseContainer
	display: flex
	flex-direction: row-reverse
	justify-content: space-between

// GENERAL - END


// NAVIGATION - START

nav#navigationMainHome
	position: relative
	width: $SIZE_LAYOUT_PROFILECONTAINER
	figure
		margin-bottom: $SPACE_BIG
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-style: solid
			+setBorderRadius(50%)
		div
			position: relative
			margin-left: auto
			margin-right: auto
			margin-bottom: $SPACE_MEDIUM
			width: $SIZE_LAYOUT_PROFILEFRAME
			height: $SIZE_LAYOUT_PROFILEFRAME
			padding: $SPACE_TINY
			border-width: $WIDTH_BORDER_PROFILETHIN
			img#imageProfilePicture, span#spanProfileNameInitial
				width: $SIZE_LAYOUT_PROFILEIMAGE
				height: $SIZE_LAYOUT_PROFILEIMAGE
				border-width: $WIDTH_BORDER_PROFILETHICK
			span#spanProfileNameInitial
				width: $WIDTH_LAYOUT_PROFILENAMEINITIAL
				height: $HEIGHT_LAYOUT_PROFILENAMEINITIAL
				padding-top: $SPACE_SMALL
				padding-bottom: $SPACE_SMALL
	ul.ListNavigation
		li.ListItemNavigation
			ul.ListSubNavigation, ul.ListSubNavigationCalculatePremium
				padding-left: $SPACE_SMALL

// NAVIGATION - END


// SIGN IN - START

div#divBodySignIn
	position: relative
	margin: auto
	width: $WIDTH_LAYOUT_SIGNIN
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	header#headerSignIn
		margin-bottom: $SPACE_BIG
		text-align: center
	form.FormSignInShow
		display: none
	form.FormSignInHide
		display: block

div.DivSignInFormContainer
	div.DivForm
		label.LabelAlignCenter
			display: flex
			flex-direction: column
			align-items: center

// SIGN IN - END


// HOME - START

div#divBodyHome
	position: absolute
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: flex
	justify-content: center
	flex-direction: column
	perspective: 1000px
	main
		position: absolute
		padding: $SPACE_ENORMOUS
		top: 0px
		left: 0px
		right: 0px
		bottom: 0px
		+setTransformStyle(preserve-3d)

img#imageLogoHeader
	position: absolute
	width: ($WIDTH_LAYOUT_LOGOHEADER + $SPACE_LITTLE)
	height: $HEIGHT_LAYOUT_LOGOHEADER
	top: ($SPACE_SMALL+$SPACE_MEDIUM)
	left: $SPACE_BIG

img#imageLogoHeaderMinimize
	height: 27px
	object-fit: cover
	margin-right: 20px

div#divBodyHome
	img#imageLogoHeader
		top: $SPACE_ENORMOUS
		right: $SPACE_ENORMOUS

img#imageLogoHeader, img#imageLogoDigital
	margin: auto $SPACE_MEDIUM

// HOME - END


// ABOUT - START

img#imageLogoAbout
	display: block
	margin-left: auto
	margin-right: auto
	width: $WIDTH_LAYOUT_LOGOABOUT
	height: $HEIGHT_LAYOUT_LOGOABOUT
	object-fit: cover

// ABOUT - END


// DASHBOARD -  START

.ChartBar
	margin-top: $SPACE_MEDIUM
	margin-bottom: $SPACE_MEDIUM

// DASHBOARD - END


// DYNAMIC CONTAINER - START

div.DivDynamicContainer
	margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableContainer
		margin-left: 0px !important
	div.DivSubDynamicContainer
		margin-top: $SPACE_MEDIUM
		padding-left: $SPACE_HUGE
		form
			width: 100%
			margin-right: 0px
	div.DivListControl
		display: flex
		justify-content: space-between
		align-items: center
		margin-bottom: $SPACE_MEDIUM
	form:last-of-type
		margin-right: 0px
	form.FormInsertConversation
		margin-bottom: 0px
		div.DivConversationGeneral
			display: flex
			flex-direction: column
			div.DivConversationHeader
				display: flex
				justify-content: space-between
				div.DivConversationInfo
					display: flex
					flex-direction: row
					div.DivConversationInfoDetails
						display: flex
						flex-direction: column
						justify-content: center
			div.DivConversationChatType
				width: 25%
			div.DivConversationContent
				display: flex
				flex-direction: column
				margin-top: $SPACE_LITTLE
				div.DivAttachmentContainer
					width: 100%
					box-sizing: border-box
					display: flex
					align-items: center
					border-bottom-left-radius: $RADUIS_LINK_BUTTONCONVERSATION
					border-bottom-right-radius: $RADUIS_LINK_BUTTONCONVERSATION
					margin-top: 0px
					padding-left: $SPACE_LITTLE
					padding-bottom: $SPACE_LITTLE
					height: $HEIGHT_LAYOUT_BUTTONATTACHMENTCONTAINER
			div.DivConversationProfile
				margin-left: $SPACE_MEDIUM
				+setBorderRadius(50%)

div.DivTransparantContainer
	padding: $SPACE_HUGE
	margin-top: $SPACE_MEDIUM
	+setBorderRadius($RADIUS_LAYOUT_FORM)

// DYNAMIC CONTAINER - END


// EXTRA - START

div.DivFormHorizontalContainer
	div.DivForm2Column
		div.DivImageCapture
			img
				display: block
				width: 95%
				height: 95%
			video
				display: block
				width: 95%
				height: 90vh

// EXTRA - END


// TABLE - START

div.DivTableContainer
	margin-left: $SPACE_SMALL
	width: 100%
	height: 100%
	div.DivTableControl
		display: flex
		justify-content: space-between
		align-items: center
		div.DivTableSubControl, div.DivTableSubControlSearch
			display: flex
			justify-content: space-between
			align-items: center
			select#selectRowPerPage
				color: #787878
		div.DivTableSubControlSearch
			width: 500px
	ul.ListTableThumbnails, table, ul.ListTableTicket, ul.ListTableConversation
		margin-top: $SPACE_LARGE
		margin-bottom: $SPACE_LARGE
	ul.ListTableThumbnails, ul.ListTableTicket, ul.ListTableConversation
		display: flex
		list-style-type: none
		+setBorderRadius($RADIUS_LAYOUT_FORM)
		li
			margin: 0px
			padding: $SPACE_LITTLE
	ul.ListTableThumbnails
		justify-content: flex-start
		align-content: stretch
		align-items: baseline
		flex-wrap: wrap
		li
			width: $WIDTH_TABLE_THUMBNAILSFIGURE
			// height: $HEIGHT_TABLE_THUMBNAILSFIGURE
			height: auto
			figure
				position: relative
				margin-left: auto
				margin-right: auto
				text-align: center
				img
					width: $SIZE_TABLE_THUMBNAILSICON
					height: $SIZE_TABLE_THUMBNAILSICON
					margin-bottom: $SPACE_LITTLE
					border: none
				figcaption
					word-wrap: normal
					white-space: normal
					word-break: break-all
	table
		+setBorderRadius($MARGIN_INPUT_SIDE)
		border-collapse: collapse
		width: 100%
		thead, tbody
			tr
				text-align: center
				th
					padding-top: $SPACE_MEDIUM
					padding-left: $SPACE_LITTLE
					padding-right: $SPACE_LITTLE
					padding-bottom: $SPACE_MEDIUM
					word-wrap: normal
					white-space: normal
					word-break: normal
				td
					padding-top: 30px
					padding-left: $SPACE_LITTLE
					padding-right: $SPACE_LITTLE
					padding-bottom: 30px
					border-bottom: 1px solid #c7c7c769
					word-wrap: normal
					white-space: normal
					word-break: normal
					figure
						img
							width: $SIZE_TABLE_THUMBNAILSICONSMALL
							height: $SIZE_TABLE_THUMBNAILSICONSMALL
				td:first-of-type, th:first-of-type
					padding-left: $SPACE_MEDIUM
				td:last-of-type, th:last-of-type
					padding-right: $SPACE_MEDIUM
			tr:last-of-type
				td
					border-bottom: 0px
				td:last-of-type
					width: 140px
		thead
				th:first-of-type
					+setBorderRadiusLeft($RADIUS_TABLE_GENERAL)
				th:last-of-type
					+setBorderRadiusRight($RADIUS_TABLE_GENERAL)
		tbody
			tr:first-of-type
				td:first-of-type
					+setBorderRadiusTopLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusTopRight($RADIUS_TABLE_GENERAL)
			tr:last-of-type
				td:first-of-type
					+setBorderRadiusBottomLeft($RADIUS_TABLE_GENERAL)
				td:last-of-type
					+setBorderRadiusBottomRight($RADIUS_TABLE_GENERAL)
	table.TableListDetails
		padding: 0px
		margin-top: $SPACE_LITTLE
		margin-bottom: 0px
		max-width: $WIDTH_TABLE_ATTACHMENT
		table-layout: fixed
		td
			padding: 0px
			figure
				margin: 0px
				img
					margin-top: $SPACE_TINY
					margin-right: $SPACE_LITTLE
		td:first-of-type
			text-align: right
			padding-left: 0px
		td:nth-child(1)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
		td:nth-child(2)
			width: $WIDTH_TABLE_ATTACHMENTBIG
			overflow: hidden
			text-overflow: ellipsis
		td:nth-child(3)
			width: $WIDTH_TABLE_ATTACHMENTMEDIUM
		td:nth-child(4)
			width: $WIDTH_TABLE_ATTACHMENTSMALL
			padding-right: 0px
	table.TableListDetailsAttachment
		td:last-of-type
			position: relative
			display: flex
			flex-flow: column wrap
			align-items: center
	ul.ListTableTicket, ul.ListTableConversation
		flex-direction: column
		li
			width: 100%
			padding-top: $SPACE_SMALL
			padding-left: $SPACE_MEDIUM
			padding-right: $SPACE_MEDIUM
			padding-bottom: $SPACE_SMALL
			display: flex
			align-items: center
			+setBoxSizing(border-box)
			img
				margin-right: $SPACE_MEDIUM
				width: $SIZE_ICON_LDPI
				height: $SIZE_ICON_LDPI
			div.DivTicketItem, div.DivConversationGeneral
				display: flex
				flex-direction: column
				flex-grow: 1
				div.DivTicketSubItem, div.DivConversationItem, div.DivTicketContentItem
					display: flex
					justify-content: space-between
					dl
						margin: 0px
						flex-grow: 1
						flex-basis: 0
					div.DivConversationStatus
						display: flex
						align-items: flex-start
					div.DivConversationHeader
						display: flex
						flex-flow: row wrap
						align-items: center
						justify-content: flex-start
						div.DivConversationProfile
							+setBorderRadius(50%)
							span.SpanChatProfileNameInitial
								width: $SPACE_BIG
								height: $SPACE_BIG
								display: flex
								align-items: center
								justify-content: center
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: 200px
					div.DivTicketTail
						span.SpanTicketAlert
							width: 7px
							height: 17px
							background-color: red
							margin-bottom: 0
							+setBorderRadius(50%)
							border-style: solid
			div.DivTicketSubItem:first-of-type, div.DivConversationItem:first-of-type
				margin-bottom: $SPACE_TINY
			div.DivTicketSubItem:last-of-type, div.DivConversationItem:last-of-type
				margin-top: $SPACE_TINY
		li:first-of-type
			+setBorderRadiusTop($RADIUS_LAYOUT_FORM)
		li:end-of-type
			+setBorderRadiusBottom($RADIUS_LAYOUT_FORM)
	ul.ListTableConversation
		li
			+setBorderRadius($RADIUS_LAYOUT_FORM)
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			margin-top: $SPACE_MEDIUM
			width: 80%
			+setBoxSizing(border-box)
		li.LiConversationContainerRight
			align-self: flex-end
			div.DivConversationItem
				div.DivConversationHeader
					span
						text-align: right
					div.DivConversationProfile
						margin-left: $SPACE_MEDIUM
						margin-right: 0px
		li.LiConversationContainerLeft
			align-self: flex-start
			flex-direction: row-reverse
			div.DivConversationItem
				flex-direction: row-reverse
				div.DivConversationHeader
					flex-direction: row-reverse !important
					span.SpanConversationDate
						text-align: left
					div.DivConversationProfile
						margin-left: 0px
						margin-right: $SPACE_MEDIUM
		li:first-of-type
			margin-top: 0px

div.DivSubDynamicContainer
	div.DivTableContainer
		margin-left: 0px

div.DivCenterGeneral
	div.DivCenterContainer
		div.DivTableContainer
			margin-left: 0px
	div.DivDynamicContainer
		margin-left: 0px

table.TableBox, table.TableBoxDocumentRiplay
	border: 1px
	tr
		td:last-of-type
			div.DivContentButtonDetail
				margin: 20px auto
			img#imageDownload
				width: 40px
table.TableBoxDocumentRiplay
	thead, tbody
		tr
			th
				width: auto

// TABLE - END


// LOADING - START

div#divCurtainLoading
	position: fixed
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	display: none
	z-index: 10
	div#divLoadingBar
		width: 0%
		height: $HEIGHT_LOADING_BAR
		display: none
	div#divLoadingMessage, img#imageLoadingCircle
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		margin: 20px auto
	div#divLoadingMessage
		width: 100%
		height: 100%
		img#imageLoadingCircle
			width: 100px
			height: 100px
			background-color: none
		span
			text-align: center

// LOADING - END


// NOTIFICATION - START

div#divNotificationInstall, div#divNotificationInstallList
	display: flex
	padding: $SPACE_LITTLE
	box-sizing: border-box
div#divNotificationInstall
	height: 100px
	justify-content: space-between
	align-items: center
	width: 100%
	position: fixed
	left: 0px
	bottom: 0px
	right: 0px
	z-index: 99
div#divNotificationInstallList
	flex-direction: column
	height: auto
	+setBorderRadius($RADIUS_INPUT_GENERAL)

// NOTIFICATION - END


div#divBodyDashboard
	div#divMainDashboard
		width: 100%
		height: 100%
		margin-top: $SPACE_MEDIUM
	div#divMainDashboard
		display: flex
		flex-wrap: nowrap
	div.DivCenterContainer
		margin-top: $SPACE_ENORMOUS
		display: block
		width: 100%
		position: relative
		margin-left: auto
		margin-right: auto
		+boxShadow(0, 2px, 10px, 0, rgba(0, 0, 0, 0.2))
		+setBorderRadius(25px)
		div.DivForm
			div.DivHeaderHeadline
				display: flex
				justify-content: space-between
				div.DivHeaderStatus
					display: flex
					align-items: flex-start
					justify-content: space-between
					span
						text-align: center
						margin-left: $SPACE_LITTLE
						margin-right: $SPACE_LITTLE
						margin-top: 0px
						padding: $SPACE_LITTLE
						+setBorderRadius($RADIUS_BUTTON_GENERAL)
					span:last-of-type
						margin-right: 0px
				div.DivSubHeaderHeadline
					label
						margin: 0
					h2
						margin-top: 5px
						margin-bottom: 5px
			fieldset
				margin-top: 0px
				div.DivForm2Column
					div.DivHeaderInfo
						margin-top: $SPACE_LITTLE
						display: flex
						justify-content: space-between
						dl
							margin: 0px
							flex-grow: 1
							flex-basis: 0
							dd
								margin: 0
				div.DivFormHorizontalContainer
					div.DivHeaderList
						align-self: flex-start
						ul
							li
								padding: 5px 10px
								width: fit-content
								margin-right: 0
								margin-bottom: 5px
								margin-top: 5px
								span
									margin: 0
								+setBorderRadius(9px)
							li:first-of-type
								margin-top: 0px
							li:last-of-type
								margin-bottom: 0px

div.DivProfileGeneral
	display: flex
	justify-content: space-between
	div.DivProfileInfo
		display: flex
		flex-direction: row-reverse
		div.DivProfileInfoDetails
			display: flex
			flex-direction: column
			justify-content: center
		div.DivProfilePhoto
			margin-right: $SPACE_SMALL
			+setBorderRadius(50%)
			span.SpanChatProfileNameInitial
				width: $SPACE_BIG
				height: $SPACE_ENORMOUS
				padding-top: $PADDING_SPAN_CONVERSATIONPROFILE
				display: flex
				justify-content: center

// CALCULATE PREMI FORM - START

form.CalculateForm
	width: 50%
	margin-left: auto
	margin-right: auto
	div.DivForm
		padding: $SPACE_MEDIUM
		fieldset
			border: none
			padding: 0px
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
			div.DivInputText
				margin: 0px
				padding: 0px
				+setBorderRadius(0px)
				display: flex
				align-items: flex-end
				span.SpanRupiah
					margin-right: 30px
		fieldset:last-of-type
			margin-bottom: 0px

// CALCULATE PREMI - END


// PRODUCT INFORMATION - START

div.DivTableContainer
	ul.ListTableThumbnails
		li
			div.DivButtonNextCalculate
				display: flex
				justify-content: center
				div.DivButtonHide
					display: none

// PRODUCT INFORMATION - END

/* THIRD PARTY  START */

// SINGLE DROPDOWN - START

div.DivFormSingleDropdown
	.ng-select
		width: 100%
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
		min-width: $WIDTH_INPUT_SQUARE
		font-family: $URL_FAMILYPRIMARY_PRIMARY
		.ng-select-container
			height: 37px
			width: 100%
			+setBorderRadius($RADIUS_INPUT_GENERAL)
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE + 6
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_SMALL 0px $SPACE_SMALL $SPACE_LITTLE
					border-radius: $SPACE_TINY
					margin-right: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					font-size: 1em
				.ng-input>input
					padding-left: $SPACE_LITTLE
	.ng-clear-wrapper
		margin-right: $SPACE_LARGE
		height: $SPACE_MEDIUM
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

//SINGLE  DROPDOWN - END

// MULTIPLE DROPDOWN - START

div.DivFormMultipleDropdown
	.ng-select
		width: 100%
		.ng-select-container
			width: 100%
			border-radius: $SPACE_LITTLE
			background-repeat: no-repeat
			background-position: right
			background-size: $SPACE_HUGE
			.ng-value-container
				flex-wrap: wrap
				width: 100%
				padding: 5px
				.ng-value
					padding-right: $SPACE_TINY
					font-size: 14px
					margin: $SPACE_TINY
					border-radius: $SPACE_TINY
					display: flex
					line-height: 0px
					align-items: center
					height: fit-content
					.ng-value-icon
						display: inline-block
						padding: $SPACE_TINY $SPACE_TINY
						padding-top: 10px
						background-color: #F7931E
					.ng-value-icon.left
						// margin-right: $SPACE_TINY
						margin-left: -$SPACE_LITTLE
						margin-top: 0px
						margin-bottom: 0px
						border-radius: 50%
						height: 20px
						border: none
						opacity: 0%
					.ng-value-label
						margin-left: 5px
						white-space: normal
						line-height: initial
						padding-top: 10px
						padding-bottom: 10px
					span
						margin-bottom: 0px
						margin-top: 0px
				.ng-value::before
					content: "-"
					width: 10px
					height: 10px
					margin-left: 5px
					margin-right: -10px
					background-color: #F7931E
					+setBorderRadius($SPACE_LITTLE)
					color: white
					text-align: center
					padding-left: 5px
					padding-right: 5px
					padding-top: 8px
					padding-bottom: 2px
					font-size: 2em
				.ng-input>input
					margin-left: $SPACE_TINY
		.ng-clear-wrapper
			position: relative
			margin-top: auto
			margin-bottom: auto
			margin-right: 25px
			padding: 0px
			span
				margin-bottom: 0px
				margin-top: 0px
				padding-left: 7px
	.ng-dropdown-panel
		.ng-dropdown-panel-items
			.ng-option
				box-sizing: border-box
				cursor: pointer
				display: block
				margin-bottom: -$SPACE_SMALL
				margin-left: $SPACE_LITTLE

// MULTIPLE DRODOWN - END

// NGX-MAT-TIMEPICKER START

div.DivForm, form div.DivForm fieldset
	table.ngx-mat-timepicker-table
		tbody.ngx-mat-timepicker-tbody
			td.ngx-mat-timepicker-spacer
				padding-top: $SPACE_LITTLE
			div, mat-mdc-form-field
				margin: 0px
				padding: 0px
				tr
					td
						height: 35px

// NGX-MAT-TIMEPICKER END

/* THIRD PARTY  END */


// CONTAINER GOOGLE MAPS PLATFORM START

	// CARD FOR BRANCH MENU DETAIL START //

div.DivFormBranch
	border-radius: 12px
	div.DivBranchCardInformation
		display: flex
		align-content: center
		margin-top: $SPACE_LITTLE
		img#imageLocation, img#imagePhone, img#imageEmail
			width: $SPACE_MEDIUM
			height: $SPACE_MEDIUM
			margin-right: $SPACE_LITTLE

	// CARD FOR BRANCH MENU DETAIL END //


	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		position: absolute 
		margin: $SPACE_MEDIUM
		width: 30%
		z-index: 1
		div.DivFormBranch
			div.DivBranchCardInformation
				img
					border-radius: 50%
					padding: $SPACE_TINY
	div.DivFormNoBorder
		google-map
			.map-container
				+setBorderRadius($SPACE_SMALL)


	// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //
	

// CONTAINER GOOGLE MAPS PLATFORM END

// MONITORING RENEWAl - START

div.DivListContainerHorizontal, div.DivListTransparantContainer
	width: 100%
	height: 100%

div.DivListContainerHorizontal
	display: flex
	flex-wrap: nowrap
	flex-direction: row
	justify-content: space-between
	div.DivListContainer
		div.DivTableSubControl
			display: flex
			justify-content: space-between
			align-items: center

div.DivListTransparantContainer
	display: flex
	flex-direction: column
	padding: $SPACE_LITTLE
	margin-left: $SPACE_TINY
	margin-right: $SPACE_TINY
	margin-top: $SPACE_HUGE
	+setBorderRadius($RADIUS_LAYOUT_CARD)
	div.DivInformationContainer
		max-height: 800px
		overflow-y: scroll

div.DivCardPolicy
	+setBorderRadius($RADIUS_LAYOUT_CARD)
	padding-top: $SPACE_LITTLE
	padding-bottom: $SPACE_LITTLE

div.DivContainerListHorizontal
	div.DivListContainer
		form
			div.DivForm
					fieldset
						div.DivRoundButtonContainer
							display: block
			input[type=button]#inputButtonSubmit
				display: none


div.DivCardInformationPolicy
	+setBorderRadius($RADIUS_LAYOUT_CARD)
	padding-top: $SPACE_SMALL
	padding-bottom: $SPACE_SMALL
	div.DivPolicyInfo
		display: flex
		justify-content: space-between
		margin-right: $SPACE_MEDIUM
		margin-left: $SPACE_LITTLE
		align-items: center
	div.DivPolicyAttributes
		display: flex
		justify-content: flex-start
		margin-left: $SPACE_MEDIUM
		margin-right: $SPACE_LITTLE
		margin-top: $SPACE_LITTLE
		align-items: center
		img
			width: $SPACE_MEDIUM
			height: $SPACE_MEDIUM

div.DivCardInformationPolicy, div.DivCardPolicy
	margin-top: 10px
	margin-bottom: 10px
	width: 100%
	height: 100%
	+setBorderRadius($RADIUS_LAYOUT_CARD)

div.DivRoundButtonContainer
	display: flex
	justify-content: flex-end
	padding: $SPACE_SMALL
	input[type=button]
		margin: 0px !important
		padding: $SPACE_LARGE
	label
		margin-top: $SPACE_LITTLE !important

/* TABLE - START */

div.DivTableListContainer, div.DivTableListContainerLeft
	width: 100%
	height: 100%
	div.DivTableListControl, div.DivTableListControlLeft
		display: flex
		align-items: center
		div.DivTableListSubControl
			display: flex
			justify-content: space-between
			align-items: center
	div.DivTableListControl
		justify-content: space-around
	div.DivTableListControlLeft
		justify-content: flex-end
		margin-bottom: $SPACE_LITTLE

table
	div.DivContentButtonDetail
		a.ButtonForwardTable
				position: relative
				margin: auto
				padding: 18px 16px
				transition: all 0.2s ease
				background-color: transparent
				text-decoration: none
		a.ButtonForwardTable:before
				content: ""
				position: absolute
				top: 0
				left: 0
				display: block
				border-radius: 45px
				width: 60px
				height: 60px
				transition: all 0.3s ease
		a.ButtonForwardTable svg
				position: relative
				top: 0
				margin-left: 10px
				fill: none
				stroke-linecap: round
				stroke-linejoin: round
				stroke: #F2F2F2
				stroke-width: 2
				transform: translateX(-5px)
				transition: all 0.3s ease
				background-color: transparent
		a.ButtonForwardTable:hover:before
				width: 100%
		a.ButtonForwardTable:hover svg
				transform: translateX(0)
		a.ButtonForwardTable:active
				transform: scale(0.96)

/* TABLE - END */

// MONITORING RENEWAl - END


/* BRINS WEBSITE - START */


// START - SCROLL SNAP



// END - SCROLL SNAP

// START - GENERAL

div#divMainDashboard
	div.DivContent2Column
		display: flex
		flex-direction: row
		margin: 20px auto
		div.DivContent, div.DivContentImage
			width: 49%
			height: auto
			margin: 0px 50px
			div.DivForm
				margin: 10px
				+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
		div.DivContent#divContentContactDetail
			width: auto
		div.DivContentImage
			display: flex
			align-items: center
			justify-content: center
			img
				width: 80%
				height: auto
		div.DivContentVideo
			display: flex
			width: 49%
			margin: 10px auto
			figure
				video
					width: 100%
					height: auto
		div.DivContentLocation, div.DivContentPartnershipWorkshop
			width: 28%
			height: auto
			margin: 0px
			div.DivContentLocationDetail
				display: flex
				flex-direction: column
				max-width: 300px
				margin: 10px auto
				word-break: break-word
				img
					margin: 10px auto
					height: 50px
				p
					text-align: center
				a.AnchorContactDetail
					text-align: center
					text-decoration: none
				a.AnchorContactDetail:first-child
					p
						margin-bottom: 0px
				a.AnchorContactDetail:last-child
					p
						margin-top: 0px
		div.DivContentLocationMaps
			width: 65%
			height: auto
			margin: 0px 30px
		div.DivContentLocation, div.DivContentLocationMaps
			div.DivFormNoBorder
				margin-top: 20px
		div:nth-child(odd)
			margin-right: 10px
			margin-top: auto
			margin-bottom: auto
		div:nth-child(even)
			margin-left: 10px
		div.DivContentClaimCenter
			width: 24%
			height: auto
			margin: 0px 20px
		div.DivContentTrackingClaim
			width: 67%
			height: auto
			margin: 0px 20px
		div#divContentLocationMaps
			margin-top: 0px
	div.DivContent3Column
		display: flex
		flex-direction: row
		div.DivContent
			width: 50%
			height: auto
			margin: 0px 20px
	div.DivContent4Column
		display: flex
		flex-direction: row
		div.DivContent
			width: 25%
			div.DivContentMain
				margin: auto 50px
		div.DivContentDescription
			display: flex
			width: 75%
			div.DivContentBoxDetail
				width: 350px
				+setBorderRadius(20px)
				+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
				margin: 10px
				background-color: #FFFFFF
				img
					width: 150px
					display: block
					margin: 20px auto
				div.DivContentBoxDescription
					margin: auto 30px
			div.DivContentButtonDetail
				margin: 50px auto
				text-align: center
	figure
		margin: 0px
		video, iframe
			+setBorderRadius(20px)
			width: -webkit-fill-available
			margin: 0px
			height: 600px
	div.DivContentAwards
		display: flex
		flex-direction: row
		margin: 20px
		width: 47%
		img
			width: 230px
			height: 230px
			+setBorderRadiusLeft(20px)
			+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
			object-fit: cover
		div.DivContentAwardsDetail
			position: relative
			display: flex
			flex-direction: column
			margin: 0px
			width: 550px
			+setBorderRadiusRight(20px)
			+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
			a.ButtonForward
				position: absolute
				bottom: 20px
				right: 30px
				svg
					position: relative
					top: 0
					margin-left: 10px
					fill: none
					stroke-linecap: round
					stroke-linejoin: round
					stroke-width: 2
					transform: translateX(-5px)
					transition: all 0.3s ease
					background-color: transparent
	section.Section2Column
		display: flex
		flex-direction: row
		justify-content: space-between
		flex-wrap: wrap
		width: 100%
		margin: 20px 0px
		div.DivSectionContent
			width: 50%
	section.Section3Column
		display: flex
		flex-direction: row
		justify-content: space-between
		width: 100%
		margin: 20px 0px
		div.DivContentTestimony
			max-width: 420px
			min-width: 340px
	section.Section4Column
		display: flex
		flex-direction: row
		justify-content: space-between
		flex-wrap: wrap
		width: 100%
		margin: 20px 0px
		div.DivSectionContent
			width: 23%
	div.DivSiteContainer
		display: flex
		flex-direction: column
		margin: 100px 80px
	img.ImageCover
		display: block
		object-fit: cover
		+boxShadow(0, 0px, 8px, 0, rgba(0, 0, 0, 0.2))
	img.ImageMediumBorder
		+setBorderRadius(30px)
	img.ImageSmallBorder
		+setBorderRadius(20px)

div.DivBodySiteContainer
	position: relative
	padding: 25px 100px
	div.DivContent2Column
		flex-wrap: wrap
	div.DivContentHorizontal
		display: flex
		flex-direction: row
		justify-content: space-between
		margin: 20px auto
		overflow-x: auto
		div.DivContentMilestoneDetail
			width: 480px
			height: 620px
			margin: 10px
			position: relative
			img
				width: 440px
				height: 320px
				+setBorderRadius(20px)
				margin: 20px
				object-fit: cover
				+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
			p
				width: 450px
				line-height: 38px
		div.DivContentMilestoneDetail:hover
			img
				width: 470px
				height: 350px
				margin: 10px
	.HeadingBorder
		margin: 15px 0px
		border: 2.5px solid #e9efef

div#divBodyHomeMenu
	display: flex
	justify-content: center
	flex-direction: column
	position: fixed
	z-index: 999
	width: 100%
	bottom: auto
	padding: 20px
	box-sizing: border-box
	nav#navigationMainHome
		width: auto
		border-radius: 25px
		background-color: #ffffff
		height: 70px
		display: flex
		flex-direction: row
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
		div.DivNavigationHome
			float: left
			margin-left: 30px
			img
				position: inherit
				margin: 15px
		div.DivNavigationHeader
			display: flex
			height: 70px
			width: 400px
			img.ImageLogoBRINS
				margin: auto 30px
				height: 30px

		div.DivNavigationList
			float: right
			margin-left: auto
			margin-right: 20px
			height: 70px
			ul
				display: flex
				height: 70px
				margin-right: 5px
				li
					margin: auto 10px
					img.ImageIconMenu
						display: block
						margin: auto
						height: 18px
						width: 18px
					img#imageIconHamburger
						height: 25px
						width: 25px
				li.ListIconNavigation
					display: flex
					margin: 20px 5px
					padding: 2px
					cursor: pointer
					img
						margin: auto
				li.ListNavigationMenu
					position: relative
					display: inline-block
					div.DivNavigationDetail
						display: none
						position: absolute
						background-color: #FFFFFF
						min-width: 160px
						z-index: 1
						padding: 10px
						border-radius: 0px 0px 20px 20px
						flex-direction: column
						margin-left: -15px
						margin-top: 10px
						box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 20px 20px 0 rgba(0, 0, 0, 0.19)
					div#divNavigationProfile
						min-width: 95px
				hr.DivNavigationBottomLine
					visibility: hidden
					display: block
					height: 6px
					border-radius: 6px
					+backgroundGradientLeftRight(#1576B3, #064F89)
					margin-top: 1px
					margin-bottom: -10px
				ul
					border-radius: 0 0 25px 25px
					height: auto
					flex-direction: column
					margin: 0px
					width: auto
					li
						margin: 5px 0px
						width: 100%
				li:hover
					cursor: pointer
					hr.DivNavigationBottomLine
						visibility: visible
				li.ListNavigationMenu:hover div.DivNavigationDetail
					display: flex
				li.ListNavigationMenuActive
					hr.DivNavigationBottomLine
						visibility: visible
	a.ANavigationMenu, div.DivNavigationMenuList, a.ANavigationListMenu
		text-decoration: none
		margin: 0px
	nav.NavHeaderMinimize
		margin-left: auto !important
		div.DivNavigationHeader
			img.ImageLogoBRINS
				margin: auto 20px !important

// END - GENERAL

//START - MENU

div#divHeaderMenu
	display: flex
	justify-content: center
	margin-top: 20px
	overflow: hidden
	position: fixed
	top: 0
	width: 100%
	div.DivNavLogo
		img
			margin: 15px 10px
	div.DivNavHeaderMenu
		width: 95%
		background-color: #FFFFFF
		height: 80px
		border-radius: 20px
		ul
			margin: 0px 35px
			li
				float: right
				display: block
				color: black
				text-align: center
				padding: 28px 15px
				text-decoration: none
				a
					font-weight: 500
					text-decoration: none
					color: black
				a: hover
					text-decoration: underline
					border-bottom-color: black
					border-bottom-style: solid
					border-bottom-width: 100%
					border-bottom-left-radius: 3px
					border-bottom-right-radius: 3px
			li: hover
				background: white
				color: black

select.SelectNavigationLanguage
	background-color: #FFFFFF
	border: 0px
	background-image: none
	height: 20px
	margin: auto
	option
		height: 25px
		width: 30px
select:focus
	outline: none

div.DivConversationMelisa
	display: flex
	position: fixed
	right: 40px
	bottom: 10px
	div.DivBannerMelisa
		display: flex
		width: 200px
		height: 40px
		cursor: pointer
		+setBorderRadius(30px)
		div.DivBanner
			margin: auto 15px
			width: 115px
		img
			position: absolute
			height: 70px
			width: 70px
			bottom: -15px
			right: -10px
	div.DivConversation
		display: flex
		flex-direction: column
		width: 300px
		height: 550px
		+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
		+setBorderRadius(10px)
		div.DivConversationHeader
			display: flex
			height: 70px
			justify-content: space-between
			width: 100%
			+setBorderRadiusTop(10px)
			img
				display: block
			div.DivConversationClose
				margin-left: auto
				margin-top: auto
				margin-bottom: auto
				img
					height: 20px
					float: right
					margin: auto 20px
					cursor: pointer
			div.DivProfileMelisa
				margin: auto 10px
				display: flex
				img
					height: 40px
					margin: auto 0px
					cursor: pointer
		div.DivConversationDetail
			display: flex
			overflow: auto
			flex-direction: column
			height: 450px
			width: 100%
			div.DivConversationMessage
				display: flex
				height: auto
				max-width: 200px
				margin: 5px 10px
				margin-left: auto
				+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
				+setBorderRadius(5px)
			div.DivConversationMessage:nth-child(1)
				margin-top: 10px
		div.DivConversationSendMessage
			display: flex
			height: 70px
			width: auto
			img
				width: 25px
				display: block
				margin: auto 15px
div.DivConversationChatBanner
	right: 40px
	bottom: 25px

//END - MENU

//START - DASHBOARD

div.DivBodyGeneral
	display: flex
	flex-direction: column
	height: 100%
	width: 100%
	background-position: center
	background-repeat: no-repeat
	background-size: cover
div#divMainDashboard
	width: auto
	height: auto
	p
		margin: 50px 0px
	p.ParagraphCenter
		text-align: center
	p.ParagraphJustify
		text-align: justify
	ol.OrderedNumber
		margin-left: 50px
		margin-top: 10px
		margin-bottom: 20px
		font-family: 'Poppins-Regular'
	div.DivBodyGeneral
		width: 100%
		height: 100vh
		div.DivHorizontalContainerCenter
			justify-content: center
		div.DivContentCorporate
			height: auto
			width: auto
			margin: auto 100px
			div.DivContentCorporatePage
				margin: 20px 0 20px 0
				text-align: center
				div.DivContentCorporatePageImage
					position: relative
					display: flex
					height: 380px
					img
						box-sizing: border-box
						position: absolute
						display: block
						margin-left: auto
						margin-right: auto
						left: 0
						right: 0
						width: auto
						height: 380px
						object-fit: cover
					h1
						font-size: 140pt
						width: 100%
						margin: auto 0px
						z-index: 1
			div.DivContentCorporateDescription
				text-align: center
				margin-bottom: 30px
				p
					font-size: 14pt
					font-weight: 550
					line-height: 1.6
					color: #a9a9a9
					text-align: center
				p.PCorporateDescription
					font-size: 2em
					color: #FFFFFF
			div.DivContentDescription
				padding: 0px 250px
				text-align: center
				h1
					font-size: 20pt
					font-weight: bold
		div.DivContentImageCorousel
			height: auto
			width: auto
			padding: 10px
		div.DivContentDescription
			div.DivContentBrinsUpdate
				p
					display: -webkit-box
					overflow-y: hidden
					-webkit-line-clamp: 2
					-webkit-box-orient: vertical
					text-align: center
					&:not(:first-child)
						display: none
				ol
					display: none
			p#PDateUpdate
				span
					color: #F26F21

div#divBodyDashboardBRIGroup, div#divBodyDashboardPartners
	div.DivContent
		div.DivContentImageGallery
			display: flex
			flex-direction: row
			height: auto
			width: auto
			padding: 10px
			div.DivButtonNavigate
				margin: auto 0px
				width: 55px
				height: 55px
				img
					width: 15px
					height: 15px
					padding: 20px
					+setBorderRadius(25px)
					+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))

section#sectionImageGallery
	display: flex
	flex-direction: row
	justify-content: space-evenly
	margin: 0px auto
	position: relative
	width: 1240px
	height: 230px
	perspective: 1400px
	transform-style: preserve-3d
	label
		img
			+setBorderRadius(15px)
	label.labelImagePrevious, label.labelImageNext
		display: flex
		img
			width: 200px
			display: block
			margin: auto 20px
	label.labelImageActive
		display: flex
		img
			width: 300px
			display: flex
			margin:	auto 20px

//START - DASHBOARD BRI GROUP


/* START - LANDING */

div#divMainLanding
	input[type=button].ButtonResponsive, input[type=button].ButtonResponsive2
		display: none

div#divBodyDashboard1
	div.DivContentDashboardBackground
		position: absolute
		max-width: 900px
		height: 500px
		margin: auto
		margin: 190px 0
		margin-left: auto
		margin-right: auto
		background-color: rgb(72,72,72,0.3)
		-webkit-filter: blur(5px)
		-moz-filter: blur(5px)
		-o-filter: blur(5px)
		-ms-filter: blur(5px)
		filter: blur(5px)
	div.DivContentSectionActive
		display: flex
	div.DivContentDashboard
		display: flex
		align-content: center
		align-items: center
		justify-content: space-between
		flex-grow: 3
		div.DivContentBackground
			position: absolute
			width: 100%
			height: 100%
			top: 0px
			background-color: rgb(72,72,72,0.3)
			-webkit-filter: blur(10px)
			-moz-filter: blur(10px)
			-o-filter: blur(10px)
			-ms-filter: blur(10px)
			filter: blur(10px)
			border-radius: 50px
		div.DivContentSection
			filter: blur(0)!important
			-webkit-filter: blur(0)!important
			margin: 20px 100px
			flex-direction: column
			height: 50vh
			align-items: center
			width: 100%
			div.DivContentTitle
				display: flex
				margin-bottom: 50px
				text-align: center
				height: 100vh
				box-sizing: border-box
				h3
					font-size: 4em
					font-weight: bold
					color: white
					line-height: 1.4
			div.DivContentDescription
				text-align: center
				height: 200px
				p
					font-size: 1.25em
					color: white
	div.DivPage
		flex-grow: 1
		align-self: center
	div.DivContentSearchContainer
		display: flex
		div.DivContentSearch
			width: 600px
			margin: 10px auto
			padding: 10px

div#divBodyDashboardBussinesPillars
	background: #ffffff
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	div.DivContentLayoutContent
		width: 100%
		height: auto
		margin: auto 0
		div.DivContentBackground
			position: absolute
			width: 100%
			height: 500px
			margin: 150px auto
			display: flex
			justify-content: center
			flex-direction: row
			img#imageLogoBackgroundCorporation
				margin: 5px
	div.DivContentLayoutSide
		display: inline-flex
		justify-content: center
		flex-direction: column
		span
			margin: 2px 20px
		span.active
			width: 12px
			height: 45px
	div.DivContentCorporateDescription
		margin: 0px 50px
		max-width: 100%
		margin-bottom: 50px

div#divBodyDashboardAboutBRINS
	div.DivContent
		margin: auto 100px
		div.DivContentTitle
			display: flex
			flex-direction: row
			margin-bottom: 20px
			hr
				margin: 35px auto 20px 10px
				height: 3px
				background: #FFFFFF
		div.DivContentButtonDetail
			text-align: left
			margin-left: 100px
			margin-top: 20px

div#divBodyDashboardAward
	div#divMainAwards
		margin: auto 0
	div.DivContentAwardsTitle
		text-align: center
	div.DivContentAwardsContent
		margin: 40px
		height: 400px
		display: flex
		flex-direction: row
		position: relative
		overflow-x: auto
		hr
			position: absolute
			top: 195px
			height: 10px
			background-color: green
			margin: 0px
	div.DivContentAwardsContentActive
		display: block
	div.DivContentAwardsDescription
		margin: 0 70px 0 70px
	div.DivContentAwardsDescriptionActive
		display: block
	div.DivContentAwardsPage
		height: 50px
	div.DivContentCalenderDetail
		position: relative
		top: 220px
		width: auto
		height: 180px
		display: flex
		div.DivContentCalenderDate
			width: 70px
			background-color: #e2eef6
			border-radius: 25px 0px 0px 25px
			display: flex
			flex-direction: column
		div.DivContentCalenderDescription
			position: relative
			display: flex
			width: 300px
			border-radius: 0px 25px 25px 0px
			background-color: #d2e3ee
			margin-left: 5px
			flex-direction: column
			div.DivContentCalenderTitle
				display: flex
				width: auto
				height: 110px
				p#paragraphBestAwardTitle
					overflow-y: hidden
					display: -webkit-box
					-webkit-line-clamp: 4
					-webkit-box-orient: vertical
			div.DivContentCalenderImage
				display: flex
				margin: 5px
				img
					border: none
					width: 50px
					height: 50px
					margin: 5px
					border-radius: 10px
					object-fit: cover
	.DivContentCalenderDetail:nth-child(even)
		position: relative
		top: 0px

div#divBodyDashboardBRINSUpdate
	padding-top: 20px
	div.DivContentCorporateSlider
		background: #ffff
		height: auto
		width: auto
		margin: auto 100px
		div.DivContentCorporateTitle
			display: flex
			height: 130px
			h1
				margin: 0px 30px
			div.DivContentTextDetail
				width: 550px
				margin: 0px 30px
			div.DivContentButtonDetail
				margin-left: auto

div#divBodyDashboardPartners
	div.DivContentPartners
		position: relative
		margin-top: auto
		margin-left: 100px
		margin-right: 100px
		margin-bottom: auto
		width: auto
		height: 360px
		+setBorderRadius(30px)
		div.DivContent2Column
			margin: 35px 50px
			height: 290px
		div.DivContent:nth-child(odd)
			width: 60%
		div.DivContent:nth-child(even)
			width: 40%
		div.DivContentButtonDetail
			position: absolute
			bottom: 50px
			margin-top: 30px
			margin-left: 0px
			text-align: left
			margin-bottom: 20px
		div.DivContentShapeDescription
			img:first-of-type
				height: 290px
				width: 290px
				margin-left: auto
			img:last-of-type
				width: 200px
				left: auto
				right: 50px
			img#logosPartners
				right: 25px !important
		img#imageIconPartners
			position: absolute
			bottom: 0px
			height: 150px
			left: 400px

/* LANDING- END */

div#divBodySearch
	display: flex
	flex-direction: column
	height: 100vh
	margin: 130px 80px 0px 80px
	height: auto
	div.DivContentSearch
		width: 1000px
		padding: 10px
		margin: 0px auto
		div.DivContentSearchDetailFilter
			input
				width: 800px
	div.DivContentResult
		display: flex
		flex-direction: column
		margin: 30px auto
		+setBorderRadius(20px)
		div.DivContentResultDetail
			display: flex
			margin: 15px auto
			width: 100%
			+setBorderRadius(20px)
			cursor: pointer
			img
				width: 160px
				height: 160px
				display: block
				object-fit: cover
				margin: 0px auto
				+setBorderRadius(20px)
			div.DivContentResultDescription
				display: flex
				flex-direction: column
				margin: 10px 20px
	div.DivContentNotFound
		display: flex
		flex-direction: column
		margin: auto
		img
			display: block
			width: 300px
			height: auto
			margin: 10px auto


// Corousel Image Slider

#sectionImageCorousel
	position: relative
	width: 40vh
	height: 40vh
	margin: auto
	perspective: 1400px
	transform-style: preserve-3d

input[type=radio]
	position: relative
	display: none
	top: 108%
	left: 50%
	width: 18px
	height: 18px
	margin: 0 15px 0 0
	opacity: 0.4
	transform: translateX(-83px)
	cursor: pointer

input[type=radio]:nth-child(7)
	margin-right: 0px

input[type=radio]:checked
	opacity: 1

#sectionImageCorousel label,
#sectionImageCorousel label img
	position: absolute
	width: 100%
	height: 100%
	left: 0
	top: 0
	color: white
	font-size: 70px
	font-weight: bold
	border-radius: 25px
	cursor: pointer
	display: flex
	align-items: center
	justify-content: center
	transition: transform 400ms ease
	object-fit: cover

/* Slider Functionality */

/* Active Slide */

$base-color: #036

#s0:checked ~ #slide0,
	#s1:checked ~ #slide1,
		#s2:checked ~ #slide2,
    		#s3:checked ~ #slide3,
    			#s4:checked ~ #slide4,
        			#s5:checked ~ #slide5,
            			#s6:checked ~ #slide6
						box-shadow: 0 13px 26px rgba(0,0,0, 0.3), 0 12px 6px rgba(0,0,0, 0.2)
						transform: translate3d(0%, 0, 0px)

/* Next Slide */

#s0:checked ~ #slide1,
	#s1:checked ~ #slide2,
		#s2:checked ~ #slide3,
			#s3:checked ~ #slide4,
				#s4:checked ~ #slide5,
					#s5:checked ~ #slide6,
						#s6:checked ~ #slide0
						box-shadow: 0 6px 10px rgba(0,0,0, 0.3), 0 2px 2px rgba(0,0,0, 0.2)
						transform: translate3d(70%, 0, -100px)

/* Next to Next Slide */
#s0:checked ~ #slide2,
	#s1:checked ~ #slide3,
		#s2:checked ~ #slide4,
			#s3:checked ~ #slide5,
				#s4:checked ~ #slide6,
					#s5:checked ~ #slide0,
						#s6:checked ~ #slide1
						box-shadow: 0 1px 4px rgba(0,0,0, 0.4)
						transform: translate3d(140%, 0, -250px)

/* Next to Next Last Slide */
#s0:checked ~ #slide3,
	#s1:checked ~ #slide4,
		#s2:checked ~ #slide5,
			#s3:checked ~ #slide6,
				#s4:checked ~ #slide0,
					#s5:checked ~ #slide1,
						#s6:checked ~ #slide2
						box-shadow: 0 1px 4px rgba(0,0,0, 0.4)
						transform: translate3d(210%, 0, -400px)

/* Previous Slide */
#s0:checked ~ #slide6,
	#s1:checked ~ #slide0,
		#s2:checked ~ #slide1,
			#s3:checked ~ #slide2,
				#s4:checked ~ #slide3,
					#s5:checked ~ #slide4,
						#s6:checked ~ #slide5
						box-shadow: 0 6px 10px rgba(0,0,0, 0.3), 0 2px 2px rgba(0,0,0, 0.2)
						transform: translate3d(-70%, 0, -100px)

/* Previous to Previous Slide */
#s0:checked ~ #slide5,
	#s1:checked ~ #slide6,
		#s2:checked ~ #slide0,
			#s3:checked ~ #slide1,
				#s4:checked ~ #slide2,
					#s5:checked ~ #slide3,
						#s6:checked ~ #slide4
						box-shadow: 0 1px 4px rgba(0,0,0, 0.4)
						transform: translate3d(-140%, 0, -250px)

/* Previous to Previous Last Slide */
#s0:checked ~ #slide4,
	#s1:checked ~ #slide5,
		#s2:checked ~ #slide6,
			#s3:checked ~ #slide0,
				#s4:checked ~ #slide1,
					#s5:checked ~ #slide2,
						#s6:checked ~ #slide3
						box-shadow: 0 1px 4px rgba(0,0,0, 0.4)
						transform: translate3d(-210%, 0, -400px)

div#divBodyDashboardBRIGroup, div#divBodyDashboardPartners
	div.DivContent
		margin-top: auto
		margin-left: 100px
		margin-right: 100px
		margin-bottom: auto
		height: auto

div.DivContentSection
	display: none

/* Next & previous buttons */
.prev, .next 
	cursor: pointer
	position: absolute
	top: 50%
	width: auto
	margin-top: -22px
	padding: 16px
	color: white
	font-weight: bold
	font-size: 18px
	transition: 0.6s ease
	border-radius: 0 3px 3px 0
	user-select: none

/* Position the "next button" to the right */
.next 
	right: 0
	border-radius: 3px 0 0 3px


/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover
	background-color: rgba(0,0,0,0.8)


/* Caption text */
.DivContentText
	color: #f2f2f2
	font-size: 15px
	padding: 8px 12px
	position: absolute
	bottom: 8px
	width: 100%
	text-align: center


/* Number text (1/3 etc) */
.DivContentNumber 
	color: #f2f2f2
	font-size: 12px
	padding: 8px 12px
	position: absolute
	top: 0


/* The dots/bullets/indicators */

.SpanContentPage
	cursor: pointer
	height: 12px
	width: 12px
	margin: 0 2px
	opacity: .6
	border-radius: 6px
	display: inline-block
.SpanContentPageDark
	background-color: #064F89
.SpanContentPageSoft
	background-color: #FFFF

.active
	opacity: inherit
	width: 45px
.activeDark
	background-color: #064F89
.activeSoft
	background-color: #FFFF

.dot:hover
	background-color: #064F89
	opacity: inherit


/* Fading animation */
.fade
	-webkit-animation-name: fade
	-webkit-animation-duration: 1.5s
	animation-name: fade
	animation-duration: 1.5s

@-webkit-keyframes fade
	0%
		opacity: 0
	100%
		opacity: 1

@keyframes fade
	0%
		opacity: 0
	100%
		opacity: 1

//END - DASHBOARD


//START - FOOTER

div#divBodyFooterContainer
	background: #CDDCE7
	height: 100vh
	display: flex
	div.DivFooterInfoPage
		display: flex
		flex-direction: row
		justify-content: space-between
		margin: auto 20px
		width: 100%
		div.DivFooterVerticalContainer
			width: 35%
			padding: 0 20px
			label
				display: flex
				font-size: 17pt
				font-weight: 600
				margin-bottom: 10px
			label.labelHeaderTitleCenter
				justify-content: center
			div.DivFooterHorizontalContainer, div.DivFooterHorizontalContainerSpace
				display: flex
				flex-direction: row
				div.DivParagraphLink
					a
						text-decoration: none
				div.DivFooterSVGContainer
					img
						display: block
						width: 20px
						height: 20px
						margin: 5px auto
				div.DivFooterSVGLargeContainer, div.DivFooterSVGMediumContainer
					img
						height: 70px
					img.ImgPNG
						width: 151.41px
			div.DivFooterHorizontalContainerSpace
				justify-content: space-around
			div.DivFooterSocialMedia
				margin-top: 10px
				margin-bottom: 30px
				div.DivFooterHorizontalContainer
					gap: 20px
					justify-content: flex-start
					margin: auto
					div.DivFooterSVGContainer
						img
							display: block
							width: 30px
							height: 30px
			div.DivFooterHorizontalContainer
				gap: 20px
				margin-bottom: 20px
		div.DivFooterPageHorizontalContainer
			display: flex
			flex-direction: column
			width: 65%
			padding: 0 20px
			div.DivFooterSectionPage
				display: flex
				justify-content: space-between
				width: 100%
				div#divFooterSubscribeContainer
					width: 900px
					padding-bottom: 0
					div.DivFooterVerticalContainer
						width: auto
						padding: 0
						div.DivFormSubscribeEmail
							width: 70%
							button
								img
									display: block
									margin: auto
									height: 20px
									width: 20px
		div.DivFooterLink
			width: auto

//END - FOOTER

div#divBodyFooterContainer
	div.DivFooterContentContainer
		margin-bottom: $SPACE_MEDIUM
	div.DivFormSubscribeEmail
		position: relative
	div.DivHorizontalContainerCenter
		justify-content: center
	p.PFooterContact
		margin: 5px 10px
	div.DivFooterCopyright
		display: flex
		flex-direction: column
		margin-bottom: 0px
		margin-top: 10px
		hr
			margin: 0
			width: 200px
			background-color: #064F89
	div.DivFooterCopyrightResponsive
		display: none
		flex-direction: column
		margin-bottom: 0px
		margin-top: 10px
		hr
			margin: 0
			width: 200px
			background-color: #064F89

div#divBodyAbout, div#divBodyEditProfile, div#divBodyNotification
	margin-bottom: 50px
	a.ButtonForward, a.ButtonPlay
		position: absolute
	a.ButtonForward:first-child
		left: ($SPACE_BIG*2)
	a.ButtonForward:last-child
		left: $WIDTH_TABLE_ATTACHMENT
	h1
		line-height: 1em
		display: inline
	div.DivContentMain
		display: flex
		height: 100vh
		width: auto
		margin-bottom: 25px
		flex-direction: row
		div.DivContentAbout
			margin: 200px 100px 0px 80px
			width: 45%
			height: 50%
			div.DivContentDescription
				width: auto
				p
					display: -webkit-box
					-webkit-line-clamp: 10
					-webkit-box-orient: vertical
					overflow: hidden
					text-overflow: ellipsis
		h1
			font-size: 4em
		p
			font-size: 1.2em
			font-weight: 550
	div.DivContentImage
		width: 50%
		height: auto
		margin: 0px
		figure
			position: relative
			width: 40vw
			height: 40vw
			margin: 100px 30px 0px 30px
			padding: 25px
			img:nth-of-type(1)
				height: 100%
				width: 100%
				+setBorderRadius(50px)
				object-fit: cover
				+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
			img:nth-of-type(2)
				position: absolute
				left: 0px
				top: 0px
			img:nth-of-type(3)
				position: absolute
				right: 130px
				top: 0px
			img:nth-of-type(4)
				position: absolute
				right: 55px
				bottom: 0px
			img:nth-of-type(5)
				position: absolute
				left: 0px
				bottom: 0px
			img:nth-of-type(3),img:nth-of-type(5)
				height: $IMAGE_HEADER_ABOUT_SVG_MEDIUM_HEIGHT
				width: $IMAGE_HEADER_ABOUT_SVG_MEDIUM_WIDTH
			img:nth-of-type(2), img:nth-of-type(4)
				height: $IMAGE_HEADER_ABOUT_SVG_SMALL_HEIGHT
				width: $IMAGE_HEADER_ABOUT_SVG_SMALL_WIDTH
	div.DivContentDescription
		div.DivContentAbout
			margin: 0px 50px 30px 80px
	div.DivContentTextDescription
		margin-top: 20px
	div#divContentWhatBRINS
		display: flex
		flex-direction: column
		div.DivContentAbout
			margin: 30px 80px
			p.DivContentSectionInactive
				display: none
			p.DivContentSectionActive
				display: block
			p
				height: 180px
div#divBodyEditProfile, div#divBodyNotification
	margin-bottom: 0px !important
	div.DivContentImage
		position: relative
		figure
			position: absolute
			bottom: -100px
			right: 0
			width: 45vw
	div.DivContentMain
		div.DivContentEditProfile, div.DivContentNotification
			margin: 200px 100px 0px 80px
			width: 40%
			height: 50%
			div.DivContentDescription
				width: auto

div#divBodyMissionAndVission
	display: flex
	flex-direction: column
	div.DivContent2Column
		margin: 80px
		div.DivContentImage
			margin: 0px
			position: relative
			figure
				display: flex
				justify-content: center
				align-items: center
				img.ImageShapeCEO
					width: 100%
					left: 0px
					bottom: 0px
				img
					position: absolute
					display: block
					width: 65%
					margin: 0px auto
					bottom: -40px
				label#labelImageCEOAbout
					position: absolute
					bottom: -70px
		div.DivContent
			text-align: center
			margin: auto
			img
				height: 500px
				+setBorderRadius(40px)
			h1
				font-size: 3em
				margin-top: 0px
			p
				font-size: 2.2em
				width: 350px
				margin-top: 50px
				padding: 0 auto
				font-style: italic
				text-align: center
			h1, p
				font-weight: bold
	div.DivContentMain
		width: auto
		margin: 50px 0px
		display: flex
		flex-direction: row
		div.DivContent
			text-align: center
			margin: auto
			img
				height: 500px
				+setBorderRadius(40px)
			h1
				font-size: 3em
				margin-top: 0px
			p
				font-size: 2.2em
				width: 350px
				margin-top: 50px
				padding: 0 auto
				font-style: italic
				text-align: center
			h1, p
				font-weight: bold
	div.DivContentDescription
		display: flex
		flex-direction: row
		height: 310px
		margin: 30px 80px 100px 80px
		div.DivContentVisionDescriptionDetail, div.DivContentMissionDescriptionDetail
			display: flex
			margin: auto 10px
			h1
				margin-bottom: 0px
				margin-top: 15px
		div.DivContentMissionDescriptionDetail
			flex-direction: row-reverse
		img
			width: 100px
			height: 100px
		div.DivContentVisionDescription
			+setBorderRadiusLeft(30px)
		div.DivContentMissionDescription
			+setBorderRadiusRight(30px)
		div.DivContentVisionDescription:hover, div.DivContentMissionDescription:hover
			cursor: pointer
		div.DivContentMissionDescription
			display: flex
			flex-direction: column
			width: 50%
			div.DivContentMissionDescriptionParagraph
				display: none
				margin-top: 0px
				margin-left: 40px
				margin-right: 100px
		div.DivContentVisionDescription
			display: flex
			flex-direction: column
			width: 50%
			div.DivContentVisionDescriptionParagraph
				display: none
				display: none
				margin-top: 0px
				margin-left: 40px
				margin-right: 200px
		div.DivContentMissionDescriptionActive, div.DivContentVisionDescriptionActive
			width: 88%
			div.DivContentVisionDescriptionDetail, div.DivContentMissionDescriptionDetail
				margin-top: 25px
				margin-bottom: 0px
			img
				margin: auto 15px
			div.DivContentVisionDescriptionParagraph, div.DivContentMissionDescriptionParagraph
				display: block
		div.DivContentMissionDescriptionActive
			div.DivContentMissionDescriptionDetail
				h1
					text-align: right
		div.DivContentVisionDescriptionInactive, div.DivContentMissionDescriptionInactive
			width: 12%
			div.DivContentVisionDescriptionDetail
				flex-direction: column-reverse
				h1
					display: inline-table
					+setRotate(-90)
					white-space: nowrap
					bottom:0
					width:20px
					height:20px
					margin-bottom: 25px
					margin-left: 25px
			div.DivContentMissionDescriptionDetail
				flex-direction: column-reverse
				h1
					display: inline-table
					+setRotate(90)
					white-space: nowrap
					bottom: 0
					width: 20px
					height: 20px
					margin-bottom: 25px
					margin-left: 20px
		img
			margin: auto 15px
		h1
			margin: auto 0px
			font-size: 3em
			font-weight: bold
	div#divContentVisionMissionBRINS
		height: 400px
div#divBodyInformation
	display: flex
	height: auto
	div.DivContentMain
		width: 30%
		display: flex
		flex-direction: column
		img.ImageIconCircuit
			display: block
			width: 450px
			height: 450px
			margin: auto
		img.ImageIconCircuit:first-of-type
			margin-top: -25px
		img.ImageIconCircuit:last-of-type
			margin-bottom: -35px
		div.DivContentTextMain
			top: 450px
			margin: auto 0
			margin-left: 50px
			h1
				font-size: 2.8em
				margin: 50px 0px 50px 0px
				font-weight: bold
			p
				font-size: 1.5em
			img:nth-of-type(1)
				position: absolute
				top: -20px
				left: 100px
				width: 150px
				height: 150px
			img:nth-of-type(2)
				position: absolute
				top: 150px
				left: -50px
				width: 250px
				height: 250px
			img:nth-of-type(3)
				position: absolute
				top: 650px
				left: 170px
				width: 100px
				height: 100px
	div.DivContentDescription
		width: 70%
		display: flex
		flex-direction: row
		justify-content: space-evenly
		flex-wrap: wrap
		padding: 30px 50px
		div.DivContentBoxDetail
			position: relative
			width: 45%
			height: 470px
			+setBorderRadius(30px)
			background-color: #FFFFFF
			+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
			margin: 30px auto
			img
				height: 200px
				width: 200px
				margin: 10px 15px
				margin-bottom: 0px
			div.DivContentBoxDescription
				margin: 0px 40px
				h4
					font-size: 1.5em
					font-weight: 600
					margin: 0px
				div.DivContentBoxText
					height: 100px
					overflow: scroll
					p
						margin: 0px
						font-size: 1.1em
						font-weight: 550
			div.DivContentButtonDetail
				display: flex
				position: absolute
				bottom: 0px
				margin-left: 40px
				margin-bottom: 35px
				height: 80px
				a.ButtonForward
					margin: auto 0px
				input[type=button].ButtonResponsive
					display: none


div#divBodyPartnerAndClient
	display: flex
	flex-direction: column
	margin: 100px 80px
	height: auto
	div.DivContentDescription
		display: flex
		flex-direction: row
		overflow-y: scroll
		div.DivContentBoxDetail
			width: 160px
			height: auto
			margin: 10px
			div.DivContentBoxDescription
				width: auto
				height: auto
				padding: 15px
div#divBodyTabAbout
	margin: 80px 80px 30px 80px
	div.DivContentMain
		display: flex
		justify-content: center
		margin: 30px 0px 0px 0px
		div.DivContentTabAbout
			padding: 10px 5px
			+setBorderRadius(40px)
			width: 100%
			display: flex
			flex-wrap: nowrap
			button
				flex-basis: 350px
				flex-shrink: 1
				flex-grow: 1
				padding: 8px
				border: none
				margin: 0px 5px
				+setBorderRadius(35px)
	div.DivContentDownload
		display: flex
		flex-direction: row
		justify-content: space-evenly
		flex-wrap: wrap
		margin-top: 20px
		gap: 10px
		div.DivContentDownloadLarge, div.DivContentDownloadMedium
			width: 350px
			margin: 20px auto
			+setBorderRadius(25px)
			+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
			img
				display: block
				margin: 15px auto
				width: 150px
			div.DivContentDownloadDescription
				img
					width: 20px
					height: 20px
					margin: 0px 5px
				a
					display: flex
		div.DivContentDownloadLarge
			display: flex
			flex-direction: column
			div.DivContentDownloadDescription
				display: flex
				flex-direction: column
				margin: 10px auto
				a
					margin: 5px auto
		div.DivContentDownloadMedium
			display: flex
			div.DivContentDownloadDescription
				display: flex
				flex-direction: column
				margin: auto 10px
				a
					margin: 5px 0px
div#divTabContainer
	margin: 80px 80px 30px 80px
	div.DivContentMain
		display: flex
		justify-content: center
		margin: 30px 0px 0px 0px
		div.DivContentTabAbout
			padding: 10px 5px
			+setBorderRadius(40px)
			button
				flex-shrink: 1
				flex-grow: 1
				flex-basis: 200px
				+setBorderRadius(25px)

// START-JOINUS

div#divContentJoinUsCareers
	position: relative
	margin: 120px 80px
	height: auto
	img
		position: absolute
		top: 0px
		width: 100%
		height: 450px
		+setBorderRadius(25px)
		+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
	div.DivContent
		position: relative
		height: 450px
		padding-top: 10px
		div.DivContentDescription
			margin: 30px auto
			width: 600px
			div.DivContentTextDescription
				height: 120px
div#divContentJoinUsPartnership
	margin: 0px
	margin-top: 100px
	width: auto
	height: 900px
	div.DivContent
		position: relative
		height: auto
		margin-top: 30px
		margin-left: 200px
		margin-right: 200px
		padding-top: 30px
		div.DivContentTextDescription
			height: 80px
			margin-bottom: 20px
div#divContentBRINSAPI
	div#divContentBRINSAPI
		div.DivContent2Column
			div.DivContent
				margin-top: 120px
				margin-left: 100px
				div.DivContentButtonAbout
					margin: 50px auto
			div.DivContentImage
				img
					width: 700px
					margin-left: -200px


div.DivContentForm
	margin-left: 200px
	margin-right: 200px
	div.DivForm
		padding: 20px 100px
		div.DivFormHorizontalContainer
			margin-bottom: 50px
			input, select, textarea
				height: 50px
			textarea
				height: 100px
			div.DivFormHorizontalContainer
				margin-bottom: 0px
				width: 100%
				input:nth-child(1)
					width: 15%
					margin-right: 10px
				input:nth-child(2)
					margin-left: 10px
					width: 80%
		div.DivContentButtonAbout
			margin-bottom: 30px

// END-JOINUS

// START-CUSTOMERCARE

div#divContentCheckPolicy
	padding: 10px 100px
	border-bottom: solid 1px
	div.DivContent2Column
		div.DivContent
			margin: 0px
			h2
				font-size: 3em
			div.DivContentButtonDetail
				a.ButtonForward
					width: 100px
					.ButtonContent
						color: #FFF
						&::after
							content: "Cari"
		div.DivContentForm
			margin-left: 100px
	div.DivForm
		padding: 50px
		div.DivFormHorizontalContainer
			margin: 0px
			input
				+setBorderRadius(25px)
	div.DivContentButtonAbout
		margin-top: 40px
		div.DivContentButtonDetail
			text-align: right

div#divContentTab
	display: flex
	+setBorderRadius(25px)
	width: 100%
	height: 50px
	margin: $SPACE_NARROW 0px
	button
		width: 47%
		padding: 0px
		border: none
		margin: 7px
		+setBorderRadius(25px)

div#divContentPartnerWorkshop
	div.DivContent2Column
		margin: 0 0 80px 0
		div.DivContentPartnershipWorkshop
			background-color: #FFFFFF
			div.DivContentDescription
				margin: 100px 50px
				div.DivContentButtonAbout
					margin-top: 50px
		div.DivContentLocationMaps
			margin: 0
		div.DivContentPartnershipWorkshop
			width: 30%
			div.DivContentDescription
				p
					margin-bottom: 40px
					text-align: center
				div.DivContentButtonDetail
					margin: 0
					div.ButtonForward
						margin: auto

div#divMainDashboard
	div#divContentTicketAndCallCenter
		height: auto
		margin: 0px
		div.DivContent2Column
			margin: 0
			display: grid
			grid-template-columns: 1fr 1fr
			grid-gap: 10px
			margin-bottom: 10px
		div.DivContentMain
			display: flex
			div.DivContent
				margin-bottom: 10px
		div.DivContent
			width: 100%
			div#divContentTab
				width: 400px
				margin: 30px auto
			div.DivContentForm
				margin: 0px 20px
				form
					margin-bottom: 0px
					div.DivForm
						padding: 20px
						div.DivFormHorizontalContainer
							div.DivFormHorizontalContainer
								margin: 0px
			div#divContentFormSmall
				margin: 20px auto
				width: 600px
				div.DivForm
					padding: 0px 20px
					div.DivFormHorizontalContainer
						margin-bottom: 20px
						div.DivFormHorizontalContainer
							input
								width: 100%
							div.DivContentButton
								width: 100%
			div.DivContentDescription
				margin: 20px 100px
				div.DivContentDetail
					margin: 50px 100px
					img
						display: block
						height: 100px
						margin: 20px auto
					img.ImageMedium
						width: 150px
		div.DivContent:nth-child(odd)
			margin-left: 0px
		div.DivContent:nth-child(even)
			margin-right: 0px

div.DivHeaderBoxTitle
	height: auto
	width: auto
	img
		width: 100%
		height: 400px

div.DivSiteContainer
	div.DivTabButton
		// width: max-content
		// height: auto
		// background-color: blue
		// margin: 20px auto
		// +setBorderRadius(30px)
		// button
		// 	width: 200px
		// 	height: 50px
		// 	margin: 10px
		// 	+setBorderRadius(20px)
		// 	background-color: red

div#divContentPartnerWorkshop
	div.DivContent2Column
		justify-content: space-between
	div#divContentBoxDetailClaim
		+setBorderRadius(20px)
		+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
		background-color: #FFFFFF
		img
			width: 150px
			display: block
			margin: 20px auto
		div.DivContentBoxDescription
			margin: auto 20px
			height: 130px
		div.DivContentButtonDetail
			margin: 50px auto
			text-align: center

div#divMainCustomerCareSubmissionClaim
	img
		width: 100%
		height: 400px
		object-fit: cover
	div.DivSiteContainer
		margin-top: 20px
	div.DivHeaderForm
		margin-bottom: 50px
	div.DivHeaderSubmissionClaim
		div.DivContentNewsHorizontal
			position: relative
			margin-top: -250px
			max-width: 900px
			margin-left: auto
			margin-right: auto
			display: flex
			img
				width: 250px
				height: 250px
				margin: 50px
			div.DivContentVertical
				margin: auto 20px
				margin-right: 50px
				p, h1
					margin: 20px 0px
	div#divContentCheckPolicy
		div.DivContentForm
			form
				max-width: 600px
				margin: 0px auto
	table.TableBox
		td
			text-align: center
	table.TableBoxHover
		td:hover
			cursor: pointer

button.ButtonSubmit
	padding: 15px
	width: 300px
	margin: 10px auto
	border: none
	+setBorderRadius(30px)
	cursor: pointer

	// CUSTOMER CARE LANDING - START

div#divContentCheckPolicy, div#divContentPartnerWorkshop, div#divContentTicketAndCallCenter
	input[type=button].ButtonResponsive
		display: none

	// CUSTOMER CARE LANDING - END

	// PARTNERSHIP WORKSHOP - START
div#divCustomerCareWorkshop
	div.DivContentSearch
		display: none

div#divMainDashboard
	div#divContainerPartnershipWorkshop
		div.DivContent2Column
			div.DivContentInfo
				display: none
		div.DivContent2Column > div:nth-child(odd).DivContentLocation
				margin-top: 0px

	// PARTNERSHIP WORKSHOP - END

// END-CUSTOMERCARE


// START-SOCIALVALUE

div#divMainDashboard
	div#divBodySocialValueCEO
		display: flex
		margin-top: 100px
		div.DivContent2Column
			margin: 100px auto
			div.DivContentImage
				margin: 0px
				img
					display: block
					width: 70%
					margin: auto
			div.DivContent
				display: flex
				margin: 0px auto
				margin-right: 0px
				width: 45%
	div#divBodySocialValueCSR
		div.DivContentDescription
			div.DivContentMain
				display: flex
				justify-content: space-between
				margin-bottom: 30px
				margin-top: 30px
				div.DivContentBoxDetail
					width: 400px
					height: 400px
					+setBorderRadius(20px)
					+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
					margin: 20px
					img
						width: 100%
						height: 230px
						display: block
						margin: 0px
						+setBorderRadiusTop(20px)
					div.DivContentBoxDescription
						margin: 15px
						height: 140px
						overflow-x: scroll
	div#divSocialValueBRINSUpdate
		height: 600px
		div.DivContentImageCorousel
			margin: 50px auto
	div#divBodySocialValueInformation
		display: flex
		margin: 50px 80px
		padding: 0px
		div.DivContentMain
			display: flex
			flex-direction: column
			width: 25%
			div.DivContentBoxDetail
				width: 250px
				height: 250px
				+setBorderRadius(20px)
				+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
				margin: 10px
				display: flex
				cursor: pointer
				div.DivContentDetail
					margin: auto
					img
						display: block
						margin: auto
						width: 120px
					div.DivContentBoxDescription
						width: 200px
						margin: auto
			div.DivContentBoxDetailShow
				display: flex
			div.DivContentTabAbout
				display: flex
				justify-content: space-between
		div.DivHide
			display: none
		div.DivShow
			display: flex
		div.DivContentDescription
			display: flex
			flex-direction: column
			width: 75%
			div#divBodyTabAbout
				margin: 0px
				div.DivContentMain
					width: 100%
					figure
						height: auto
						width: auto
						img
							width: 100%
					div.DivContentTabAbout
						button
							width: 215px
					img
						margin: 30px 0px
						+setBorderRadius(30px)
		div.DivForm
			padding: 0px


// END-SOCIALVALUE


// START - CORPORATE VALUE

div#divMainDashboard
	div#divContent2ColumnCoreValue, div#divContent2ColumnBrillianWays
		div.DivContentImage
			img
				+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
		div.DivContent
			div.DivBodyContentOrderedList
				p
					text-align: left


// END - CORPORATE VALUE

div.DivBodyGeneral
	height: 100vh
	scroll-snap-align: start
	position: relative

div#divMainLanding
	div#divBodyFooterContainer
		height: 100vh
		scroll-snap-align: start
		position: relative
		display: flex

div.DivBodyGeneralNoSnap
	height: 100vh
	scroll-snap-stop: normal
	position: relative

div.DivBodyContainer
	position: relative

div.DivContentShapeDescription
	position: relative
	img:first-of-type
		margin-top: auto
		margin-bottom: auto
		margin-left: 0px
		display: block
		height: 160px
		width: 160px
	img:last-of-type
		position: absolute
		display: block
		top: 0
		bottom: 0
		margin: auto 20px
		width: 120px

div.DivContentPoint
	position: absolute
	bottom: 0px
	width: 100%
	height: 240px
	overflow-y: scroll
	div.DivContentPointActive
		margin: 20px auto
		width: 20px
		height: 20px
		border-radius: 25px
		background: white
		border: solid 15px #1ab349

div.DivContentButtonDetail
	.ButtonForward, .ButtonPlay
		position: relative
		margin: auto
		padding: 26px 24px
		transition: all 0.2s ease
		background-color: transparent
		text-decoration: none
	.ButtonForwardInstall
		position: relative
		margin: auto
		padding: 8px 25px
		transition: all 0.2s ease
		background-color: transparent
		text-decoration: none
	.ButtonForward:before, .ButtonPlay:before, .ButtonForwardInstall:before
		content: ""
		position: absolute
		top: 0
		left: 0
		display: block
		border-radius: 45px
		transition: all 0.3s ease
	.ButtonForward:before, .ButtonPlay:before
		width: 76px
		height: 76px
	.ButtonForwardInstall:before
		width: 40px
		height: 40px
	.ButtonPlay
		svg
			margin-left: 8px
		img
			width: 25px
			height: 25px
	.ButtonPlay:before
		width: 66px
		height: 66px
		border: 5px solid #064F89
	.ButtonForward svg, .ButtonForwardInstall svg
		position: relative
		top: 0
		fill: none
		stroke-linecap: round
		stroke-linejoin: round
		stroke: #F2F2F2
		stroke-width: 2
		transform: translateX(-5px)
		transition: all 0.3s ease
		background-color: transparent
	.ButtonForward svg
		margin-left: 10px
	.ButtonForwardInstall svg
		margin-left: 5px
	.ButtonForward:hover:before, .ButtonForwardInstall:hover:before
			width: 100%
	.ButtonForward:hover svg, .ButtonForwardInstall:hover svg
			transform: translateX(0)
	.ButtonForward:active, .ButtonForwardInstall:hover svg
			transform: scale(0.96)

div.DivContentButtonInline
	width: 100%
	margin: 20px 0px
	display: flex
	justify-content: space-between
	.ButtonForward, .ButtonPlay, input[type=button].ButtonResponsive
		position: relative !important
		left: 0px !important

div#divBodyStructureOrganization
	border-bottom: 1px
	div.DivContentMain
		h1
			margin-left: 150px
	div.DivContentDescription:nth-child(odd)
		display: flex
		flex-direction: row-reverse
	div.DivContentDescription:nth-child(even)
		display: flex
		flex-direction: row
	div.DivContentDescription
		padding: 20px 100px
		margin-top: 50px
		img
			margin: 20px
			height: auto
			width: 350px
		label
			text-align: center
		div.DivContentDescriptionDetail
			margin: 20px
		div.DivImageDirector
			margin: 0 auto
	div.DivContentImage
		img
			width: 80%
			height: auto
			display: block
			margin-left: auto
			margin-right: auto
			+setBorderRadius(0px)
	div.DivContentMainDivision
		display: flex
		margin: 50px 75px 0px 75px
		img
			display: block
			width: 300px
			margin: 30px 15px
		h1
			margin: auto
	div.DivContentDescriptionDivision
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: space-evenly
		margin: 30px 70px 0px 70px
		img
			display: block
			width: 300px
			margin: 30px 10px
div.DivBodyStructureOrganizationShow
	display: block

div.DivBodyStructureOrganizationHide
	display: none

div#divMainDashboard
	div.DivContent2Column
		div.DivContent
			margin: 0px
			div.DivContentOrderedListDescription
				width: 100%
		div.DivContentImage
			img
				display: block
				margin: auto
				+setBorderRadius(30px)
	div#divContent2ColumnBRIOne
		div.DivContentImage
			margin: 0 10px
			img
				width: 100%
				border-radius: 0
	div#divContent2ColumnCoreValue
		div.DivContentImage
			margin: 0px auto
			img
				margin-right: 0px
				width: 90%
	div#divContent2ColumnBrillianWays
		div.DivContentImage
			margin: 0px auto
			img
				margin: auto auto auto 0px
				width: 95%
	div#divBodyContentOrdered
		margin: 50px 100px
		div.DivBodyContentOrderedList
			display: flex
			margin-left: 0px
			margin-right: 10px
			div.DivContentOrderedListImage
				width: 130px
				padding: 10px
				margin: 30px 0px
				img
					width: 100px
					height: auto
					+setBorderRadius(10px)
			div.DivContentOrderedListNumber
				width: 50px
				h4
					margin: 18px
		div#divContentImageStategiBusiness
			margin-left: 0px
			img
				display: block
				width: 95%
				margin-left: 0px

// .ImageClipSVG
// 	clip-path: url(#SVGClipPath)

div.DivContentSearch, div.DivContentTableSearch
	display: flex
	flex-direction: row
	+setBorderRadius(30px)
	height: 50px
	background-color: #FFFFFF
	padding: 20px
	width: 80%
	margin-top: 20px
	+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
	img
		height: 25px
		width: 25px
		margin: auto 10px
	div.DivContentSearchDetail, div.DivContentSearchDetailFilter
		display: flex
		width: 100%
		margin: 0px 10px
		select
			background-color: #FFFFFF
			border: 0px
			background-image: none
			height: 30px
		select:focus
			outline: none
		input
			background-color: #FFFFFF
			border: 0px
			background-image: none
			height: 50px
			width: 400px
		input:focus
			outline: none
	div.DivContentSearchDetail
		flex-direction: column
	div.DivContentSearchDetailFilter
		select
			height: 50px
div.DivContentTableSearch
	height: 25px
	input
		width: 100%
		border: none


div.DivPageCenterAlign
	text-align: center

// START - MODAL

div.DivModal
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	margin: auto
	padding: 20px
	box-sizing: border-box
	display: none
	width: 80%
	height: max-content
	background-color: #fff
	box-sizing: border-box
	z-index: 9999
	+setBorderRadius(30px)
	div.DivModalHeader
		display: flex
		flex-direction: row
		justify-content: space-between
		img
			height: 50px
			width: 50px
			margin-top: auto
			margin-bottom: auto
			cursor: pointer
		h2
			margin: 0px
	div.DivModalFigureContainer
		height: 600px
		figure
			width: auto
			height: auto
			iframe
				height: auto
				+setBorderRadiusBottom(30px)

// END - MODAL


// START - SEARCH

div.DivContentSearchResult
	display: flex
	position: absolute
	background-color: #FFFFFF
	min-width: 160px
	z-index: 1
	padding: 10px
	border-radius: 0px 0px 20px 20px
	flex-direction: column
	margin-left: -15px
	margin-top: 60px
	box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 20px 20px 0 rgba(0, 0, 0, 0.19)
	ul
		border-radius: 0 0 25px 25px
		height: auto
		flex-direction: column
		margin: 0px
		width: auto
		list-style: none
		li
			margin: 10px
			width: 100%
		li:hover
			cursor: pointer

// END - SEARCH

// START - BRINS Update

div#divMainBRINSUpdate
	background-color: #FFFFFF
	div#divBodyHeadlines
		div.DivBodyFloatMain
			display: flex
			flex-wrap: wrap
			margin: 0px 80px
			height: auto
			section:nth-child(1)
				width: 60%
				div.DivSectionHeadlines
					display: flex
					margin-right: 25px
			section:nth-child(2)
				width: 40%
				div.DivSectionNewsFeed
					display: flex
					flex-direction: column
			input[type=button].ButtonResponsive
				display: none
			div.DivContentTitleHorizontal
				display: flex
				justify-content: space-between
				height: 100px
				margin: 20px 0px
				div.DivContentButtonDetail
					margin: auto 0px
					margin-right: 20px
			div.DivContentTestimonyDetail
				padding: 15px
				height: 300px
				+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
				+setBorderRadius(25px)
				img
					display: block
					object-fit: cover
					height: 75px
			div.DivContentCommunity
				figure iframe, img
					display: block
					object-fit: cover
					width: 100%
					height: 150px
					+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
					+setBorderRadius(25px)
					margin: 20px auto
			div.ParagraphTestimony
				overflow-y: hidden
				display: -webkit-box
				-webkit-line-clamp: 4
				-webkit-box-orient: vertical
	div#divFinancialLiteracy
		margin: 50px 80px
		height: 350px
		div.DivContent2Column
			margin: 0px
			div.DivContent
				margin:	0px
				width: 50%
				div.DivFinancialLiteracy
					padding: 35px 50px
					div.ButtonForward
						margin: 20px 0px
					div.DivFinancialParagraphButton
						display: none
				div.DivFinancialLiteracyImage
					img
						display: block
						object-fit: cover
						width: 100%
						height: 350px
						+setBorderRadiusRight(25px)
			div.DivContent:nth-child(1)
				+setBorderRadiusLeft(25px)
			div.DivContent:nth-child(2)
				+setBorderRadiusRight(25px)
			div.DivContentButtonDetail
				bottom: 0
				left: 0
				top: 55px
				right: 50px
				position: relative
				margin: 30px 0px
				text-align: left


div.DivContentHorizontal
	display: flex
	justify-content: space-between

div.DivContentNewsHorizontal
	margin-bottom: 15px
	display: grid
	grid-template-columns: 170px 1fr
	+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
	+setBorderRadius(25px)
	img
		width: 170px
		height: 100%
		object-fit: cover
		display: block
		+setBorderRadiusLeft(25px)
		object-position: 40%
	div.DivContentNewsDetail
		box-sizing: border-box
		width: 100%
		padding: 15px
		+setBorderRadiusRight(25px)
div.DivContentNewsVertical
	margin-bottom: 15px
	display: flex
	width: 100%
	flex-direction: column
	+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))
	+setBorderRadius(25px)
	img
		width: 100%
		height: 400px
		object-fit: cover
		display: block
		+setBorderRadiusTop(25px)
	div.DivContentNewsDetail
		padding: 15px
		height: 240px
		+setBorderRadiusBottom(25px)
		p
			display: -webkit-box
			overflow-y: hidden
			-webkit-line-clamp: 4
			-webkit-box-orient: vertical
			&:not(:first-child)
				display: none
		div.DivHeadlineTitle
			display: -webkit-box
			overflow: hidden
			text-overflow: ellipsis
			-webkit-line-clamp: 2
			-webkit-box-orient: vertical
div.DivContentNewsSmall
	+setBorderRadius(15px)
	img
		height: 250px
		+setBorderRadiusTop(15px)
	div.DivContentNewsDetail
		overflow-y: auto
		height: 100px
		+setBorderRadiusBottom(15px)
		p
			margin: 0px !important

div#divMainBRINSUpdateArticle
	img.ImageHeaderArticle
		width: 100%
		height: 420px
		display: block
		object-fit: cover
		margin-top: 120px
		margin-left: auto
		margin-right: auto
	div.DivBRINSUpdateArticleDetail
		margin: 50px 80px
		div.DivSharelinkContent
			display: flex
			gap: 10px
			margin-bottom: 30px
			img
				width: 30px
				height: 30px
				cursor: pointer
	div.DivContentHorizontal
		display: flex
		justify-content: space-between
		margin-bottom: 50px
		flex-direction: row
	div.DivContentHorizontalHeaderArticleEditorial
		display: flex
		justify-content: space-between
		gap: 30px
		div.DivContentHorizontalAttachmentArticleEditorial
			width: 260px
			display: flex
			justify-content: flex-start
			gap: 10px
			align-items: center
			cursor: pointer
			img
				width: 40px
			label
				color: #58585A
				font-weight: 400


div#divMainBRINSUpdateArticle
	div#divCommunityContainer
		margin: 100px 80px
		height: auto
		section.Section2Column
			margin: 25px 0px
			div
				width: 100%
				margin: 0px
				figure
					display: flex
					margin-bottom: 40px
					figcaption
						width: 100%
						padding: 20px 40px
						align-self: center
					div.DivImageBackground
						position: relative
						img
							width: 100%
							height: 300px
						img.ImgPlayVideo
							position: absolute
							top: 0
							right: 0
							bottom: 0
							left: 0
							margin: auto
							width: 100px
							background-size: contain
							background-position: center
							background-repeat: no-repeat
							z-index: 5
			div.DivContent2Column
				margin: 0px
				flex-wrap: wrap
				img
					height: 290px
					width: 97%
				div.DivContent:nth-child(1)
					img
						margin-right: 10px
						margin-bottom: 10px
				div.DivContent:nth-child(2)
					img
						margin-left: 10px
						margin-bottom: 10px
				div.DivContent:nth-child(3)
					img
						margin-right: 10px
						margin-top: 10px
				div.DivContent:nth-child(4)
					img
						margin-left: 10px
						margin-top: 10px
	div.DivTableContainer
		margin-bottom: 10px
		label
			font-size: 1em
			font-family: "Poppins-Regular"
			font-weight: normal
			color: #999090

div#divMainBRINSUpdateTestimony
	div#divTestimonyContainer
		height: auto
		section.Section3Column
			overflow-x: auto
			div.DivContentTestimony
				padding: 0px
				margin: 15px
				height: auto
				div.DivContentTestimonyDetail
					margin: 25px
					width: auto
					height: 500px
					position: relative
					img.ImageIconRight
						margin-left: auto
					div.DivTestimonyParagraph
						overflow-y: scroll
						height: 180px
						margin: 10px 0px
div#divMainBRINSUpdateFinancialLiteracy
	div#divFinancialLiteracyContainer
		section.Section2Column
			div.DivSectionContent
				div.DivContentNewsVertical
					width: 97%
					img
						height: 300px
					div.DivContentNewsDetail
						height: 250px
			div.DivSectionContent:nth-child(1)
				div.DivContentNewsVertical
					margin-left: 0
					margin-right: auto
			div.DivSectionContent:nth-child(2)
				div.DivContentNewsVertical
					margin-left: auto
					margin-right: 0
		section.Section4column
			display: grid
			grid-template-columns: repeat(auto-fill,minmax(315px,1fr))
			grid-auto-flow: column
			grid-auto-columns: minmax(315px,1fr)
			grid-gap: 20px
			overflow-x: auto
			div.DivSectionContent
				width: 100%
div.DivContentTestimony
	padding: 25px
	height: 290px
	+boxShadow(0, 0, 8px, 0, rgba(0, 0, 0, 0.2))
	+setBorderRadius(30px)
	img
		display: block
		object-fit: cover
		height: 35px
	img:first-of-type
		height: 130px
		width: 130px
		margin-left: auto
		margin-right: auto
		margin-bottom: 20px
		+setBorderRadius(65px)

div.DivContentButtonMore
	display: flex
	align-items: center
	justify-content: center
	a
		padding: 10px 25px
		+setBorderRadius(30px)

// END  - BRINS Update

// START - PRIVACY

div.DivHeaderPolicy
	position: relative
	width: 100%
	height: 300px
	img#imgHeaderShape
		margin-top: -5px
	img#imgHeaderResponsive
		display: none
	h1
		position: absolute
		top: 55%
		left: 50%
		transform: translate(-50%, -50%)
		width: max-content
div.DivMainPolicy
	label
		display: block
		margin-top: 25px
		margin-bottom: 15px
	label.LabelRightSide
		text-align: right
	p
		margin-bottom: 20px
		margin-top: 20px
	button
		label
			margin-top: 0px
			margin-bottom: 0px
	section
		margin: 20px 0px
		div.DivButton2Column
			margin: 20px autox
		article
			margin: 30px 0px
			div.DivDetailFAQ
				label.LabelContainerFAQQuestion
					margin: 0px
				div.DivHorizontalContainer
					display: flex
					justify-content: space-between
					cursor: pointer
					label
						margin: auto 0px
					img
						display: block
						margin: auto 0px
						width: 50px
						height: 50px
						content: url($URL_ICON_RESOURCE + "icon_dropdown_right.svg")
			input[type=checkbox], p.ParagraphFAQAnswer
				display: none
			input[type=checkbox]:checked ~ p.ParagraphFAQAnswer
				display: block
			input[type=checkbox]:checked ~ label.LabelContainerFAQQuestion
				div.DivHorizontalContainer
					img.ImgFAQQuestion
						content: url($URL_ICON_RESOURCE + "icon_dropdownaa.svg")


div.DivButton2Column
	display: flex
	flex-direction: row
	flex-wrap: wrap
	justify-content: space-between
	width: 100%
	button
		width: 48%
		padding: 5px
		height: 50px
		border: none
		margin: 10px 0px
		+setBorderRadius(20px)
		+boxShadow(0, 0, 8px, 0, rgba(0, 0, 0, 0.2))

// END - PRIVACY

// MENU - START

nav.NavMenuOverlay
	height: 100%
	width: 0%
	position: fixed
	z-index: 1
	top: 0
	right: 0
	background-color: white
	background-color: #ffffff
	overflow-x: hidden
	transition: 0.5s
	z-index: 16
	label
		font-size: 1.2em
	div.DivMenuHeader
		display: flex
		margin: 20px
		img
			height: 50px
		img#imageLogoDigital
			height: 30px
			margin-left: 0px
		img.ImageIconMenu
			margin: auto
			margin-right: -5px
	ul.ListNavigation
		margin: 20px 10px
		list-style: none
		li.ListNavigationMenu, li.ListItemNavigation
			margin: 5px 0px
			padding: 5px 7.5px
			color: black
			ul
				margin-top: 10px
				margin-left: 20px
				li.ListItemSubNavigation
					padding: 10px 30px
					margin-top: 0px
					margin-bottom: 0px
		li.ListNavigationMenuActive, li.ListNavigationMenu:hover, li.ListItemSubNavigation:hover
			background-color: #F27024
			+setBorderRadius(5px)
			a, label
				color: #ffffff
		li.ListItemNavigation
			padding-bottom: 10px
			label
				color: black
		li.ListNavigation
			padding-bottom: 5px
			ul
				margin: 0
		img#imageMainDomain
			height: 25px
			margin: 10px
nav.NavMenuMinimizeOverlay
	width: 100%

// MENU - END


// FORM BRINS EDITORIAL - START

div#divBodyFormOutlook
	background-color: #FFF
	form
		margin-bottom: 0px
		div.DivForm
			margin: 30px 0px
			-ms-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2)
			-o-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2)
			box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2)
			fieldset
				margin-top: 0px
				div.DivFormHorizontalContainer
					div.DivForm2Column
						label
							color: #787878
						div.DivInputHorizontalContainer
							input[type=text]
								color: #787878

div.DivMainBRINSEditorialArticle
	div.DivBRINSUpdateArticleDetail
		ol, ul
			margin-left: 15px
			text-align: justify
		p
			margin: 10px 0px !important
			line-height: normal !important
			text-align: justify

// FORM BRINS EDITORIAL - END

/* BRINS WEBSITE - STOP */


// PNM DOWNLOAD - START

div#divHeaderDownload
	position: relative
	margin: auto
	width: $WIDTH_LAYOUT_SIGNIN
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	flex-direction: column
	header#headerDownload
		margin-bottom: $SPACE_BIG
		text-align: center
	form
		div.DivForm
			text-align: center
			h5
				margin-top: 20px

// PNM DOWNLOAD - END