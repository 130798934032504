/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// TYPEFACE - START

/* PRIMARY - START */

@font-face
	font-family: $URL_FAMILYPRIMARY_PRIMARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_PRIMARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_PRIMARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_PRIMARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_PRIMARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_PRIMARY + '.svg#' + $URL_FAMILYPRIMARY_PRIMARY) format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: $URL_FAMILYPRIMARY_SECONDARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_SECONDARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_SECONDARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_SECONDARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_SECONDARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_SECONDARY + '.svg#' + $URL_FAMILYPRIMARY_SECONDARY) format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: $URL_FAMILYPRIMARY_TERTIARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_TERTIARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_TERTIARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_TERTIARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_TERTIARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_PRIMARY + $URL_FAMILYPRIMARY_TERTIARY + '.svg#' + $URL_FAMILYPRIMARY_TERTIARY) format('svg')
	font-weight: normal
	font-style: normal

/* PRIMARY - END */

/* SECONDARY - START */

@font-face
	font-family: $URL_FAMILYSECONDARY_PRIMARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_SECONDARY + $URL_FAMILYSECONDARY_PRIMARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_SECONDARY + $URL_FAMILYSECONDARY_PRIMARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_SECONDARY + $URL_FAMILYSECONDARY_PRIMARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_SECONDARY + $URL_FAMILYSECONDARY_PRIMARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_SECONDARY + $URL_FAMILYSECONDARY_PRIMARY + '.svg#' + $URL_FAMILYSECONDARY_PRIMARY) format('svg')
	font-weight: normal
	font-style: normal

/* SECONDARY - END */

/* TERTIARY - START */

@font-face
	font-family: $URL_FAMILYTERTIARY_PRIMARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_PRIMARY + '.svg#' + $URL_FAMILYTERTIARY_PRIMARY) format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: $URL_FAMILYTERTIARY_SECONDARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_SECONDARY + '.svg#' + $URL_FAMILYTERTIARY_SECONDARY) format('svg')
	font-weight: normal
	font-style: normal

@font-face
	font-family: $URL_FAMILYTERTIARY_TERTIARY
	src: url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.eot?#iefix') format('embedded-opentype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.woff2') format('woff2'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.woff') format('woff'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.ttf') format('truetype'), url($URL_FONT_RESOURCE + $URL_FONT_TERTIARY + $URL_FAMILYTERTIARY_TERTIARY + '.svg#' + $URL_FAMILYTERTIARY_TERTIARY) format('svg')
	font-weight: normal
	font-style: normal

/* TERTIARY - END */

// TYPEFACE - END


// SETUP - START

/* NEUTRALIZE - START */

h1, h2, h3, h4, h5, h6, p, mark, ol, ul, li
	margin: auto
	padding: auto
	font-family: inherit
	font-size: inherit
	font-weight: inherit
	color: inherit
	text-decoration: inherit
	text-transform: inherit

ol, ul
	padding-left: 0px
	padding-right: 0px
	li
		padding-left: 0px
		padding-right: 0px

/* NEUTRALIZE - END */

/* SEMANTIC ELEMENTS - START */

img
	border: none

label, input[type=button], input[type=submit], input[type=reset], a
	cursor: pointer
	cursor: hand

*
	+setTransition(all, 0.25, ease-out)
	// "position, margin, width, height, padding, background-color, opacity, border"
	scrollbar-width: none
	-ms-overflow-style: none
	::-webkit-scrollbar
		width: 0
		height: 0

body
	position: relative
	width: 100%
	height: 100vh

form, body, footer
	padding: 0px
	margin: 0px

div.DivBackground
	position: fixed
	top: 0px
	left: 0px
	right: 0px
	bottom: 0px
	z-index: -2

footer
	position: fixed
	bottom: 0px
	left: 0px
	right: 0px
	height: $HEIGHT_LAYOUT_FOOTER
	z-index: -1

// SETUP - END


// CONDITIONER - START

.MarginTopMedium
	margin-top: $SPACE_MEDIUM

.Width100
	width: 100%

.Margin0Padding0
	margin: 0px
	padding: 0px

.PaddingLeft10
	padding-left: 10px !important

/* FLEX */

.FlexGrow0
	flex-grow: 0
.FlexGrow1
	flex-grow: 1
.FlexGrow2
	flex-grow: 2
.FlexGrow3
	flex-grow: 3
.FlexGrow4
	flex-grow: 4
.FlexGrow5
	flex-grow: 5
.FlexShrink0
	flex-shrink: 0
.FlexShrink1
	flex-shrink: 1
.FlexShrink2
	flex-shrink: 2
.FlexShrink3
	flex-shrink: 3
.FlexShrink4
	flex-shrink: 4
.FlexShrink5
	flex-shrink: 5
.JustifyContentFlexStart
	justify-content: flex-start !important

// CONDITIONER - END

// THIRDPARTY - START

div.cke_wysiwyg_div
	padding: $SPACE_LITTLE
	background-color: #E6E6E6
	ol, ul
		padding-left: revert
		padding-right: revert
		li
			padding-left: revert
			padding-right: revert
div#ckeditor
	div.cke_contents
		height: 300px !important

// THIRDPARTY - END